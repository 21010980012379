<template>
  <v-card class="charge-profile-modal">
    <v-card-title class="px-6 pt-6 primary--text">
      <div class="p-relative flex">
        <h2 class="text-h6">{{ $t('Edit Charge Profile') }}</h2>
        <v-icon class="close-button" @click="close">close</v-icon>
      </div>
    </v-card-title>

    <v-divider></v-divider>

    <v-sheet class="px-4">
      <v-form
        ref="editTaxProfile"
        lazy-validation
        @submit.prevent="updateTaxProfile"
      >
        <v-tabs
          v-model="activeTab"
          background-color="transparent"
          grow
          class="mt-2"
          slider-color="primary"
        >
          <v-tab>{{ $t('General') }}</v-tab>
          <v-tab>{{ $t('Taxes') }}</v-tab>
          <v-tab>{{ $t('Fees') }}</v-tab>
        </v-tabs>

        <v-divider></v-divider>

        <v-tabs-items v-model="activeTab">
          <!-- General Tab -->
          <v-tab-item>
            <div class="py-6 px-2">
              <v-card flat class="pa-4 mb-4 elevation-1">
                <v-card-title class="text-subtitle-1 pa-0 mb-4 primary--text">
                  {{ $t('Profile Information') }}
                </v-card-title>
                <v-text-field
                  v-model="taxProfile.name"
                  outlined
                  dense
                  :label="$t('Name')"
                  required
                  :rules="[required]"
                  class="mb-4"
                />
                <multi-property-picker
                  v-model="taxProfile.listing_ids"
                  :label="$t('Applicable Listings')"
                  :clearable="false"
                  hide-btns
                  :deletable-chips="true"
                  no-filter
                  :menu-props="{
                    closeOnContentClick: false,
                    closeOnClick: false,
                  }"
                  @click:clear.prevent.stop="() => {}"
                >
                  <template #selection="{ item }">
                    <v-chip
                      :key="item.id"
                      :input-value="true"
                      close
                      small
                      class="ma-1"
                      @click.prevent.stop="() => {}"
                      @click:close="removeListingId(item.id)"
                    >
                      <v-avatar v-if="item.picture">
                        <img :src="item.picture" />
                      </v-avatar>
                      <span class="ml-2">{{ item.nickname }}</span>
                    </v-chip>
                  </template>

                  <template #append-item>
                    <!-- Empty slot to prevent default clear-all behavior -->
                  </template>

                  <template #append-outer>
                    <!-- Empty to remove default clear icon -->
                  </template>
                </multi-property-picker>
              </v-card>
            </div>
          </v-tab-item>

          <!-- Taxes Tab -->
          <v-tab-item>
            <div class="py-6 px-2">
              <v-card
                v-if="taxesOnly.length > 0"
                flat
                class="pa-4 mb-4 elevation-1"
              >
                <v-card-title class="text-subtitle-1 pa-0 mb-4 primary--text">
                  {{ $t('Tax Entries') }}
                </v-card-title>
                <div
                  v-for="(tax, ind) in taxesOnly"
                  :key="`tax-${ind}`"
                  class="tax-item mb-4"
                >
                  <v-row class="align-center">
                    <v-col cols="5">
                      <v-autocomplete
                        v-model="tax.tax_type"
                        outlined
                        dense
                        hide-details
                        :items="taxTypesOnly"
                        label="Tax Type"
                        :rules="[required]"
                      />
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="tax.name"
                        outlined
                        dense
                        hide-details
                        :label="$t('Title')"
                        required
                        :rules="[required]"
                      />
                    </v-col>
                    <v-col cols="2"> </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="tax.amount"
                        type="number"
                        outlined
                        dense
                        hide-details
                        :label="$t('Amount')"
                        required
                        :rules="[required]"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        v-model="tax.units"
                        outlined
                        dense
                        hide-details
                        :items="taxUnits"
                        label="Unit"
                        :rules="[required]"
                      />
                    </v-col>
                    <v-col v-if="tax.units === 'fixed'" cols="5">
                      <v-select
                        v-model="tax.quantifier"
                        outlined
                        dense
                        hide-details
                        :items="taxQuantifier"
                        label="Quantifier"
                        :rules="[required]"
                      />
                    </v-col>
                    <v-col v-if="tax.units === 'percentage'" cols="5">
                      <v-select
                        v-model="tax.applicable_to"
                        outlined
                        dense
                        hide-details
                        multiple
                        small-chips
                        :items="taxApplicableTo"
                        label="Tax applicable to"
                        :rules="[required]"
                      />
                    </v-col>
                    <v-col cols="1">
                      <v-icon
                        class="ml-auto"
                        small
                        color="error"
                        @click.stop="removeTax(getOriginalIndex(tax))"
                        >$trash</v-icon
                      >
                    </v-col>

                    <v-col
                      v-if="
                        tax.tax_type === 'VAT' || tax.tax_type.includes('tax')
                      "
                      cols="6"
                    >
                      <v-text-field
                        v-model="tax.max_days"
                        type="number"
                        outlined
                        dense
                        hide-details
                        :label="$t('Apply tax only up to (days)')"
                        :hint="
                          $t('Leave empty to apply for all stay durations')
                        "
                      />
                    </v-col>
                    <v-col v-if="tax.tax_type === 'VAT'" cols="3">
                      <v-checkbox
                        v-model="tax.inclusive"
                        :label="$t('Price inclusive VAT')"
                        hide-details
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-divider
                    v-if="ind < taxesOnly.length - 1"
                    class="my-3"
                  ></v-divider>
                </div>
              </v-card>

              <v-card v-else flat class="pa-4 elevation-1 text-center">
                <v-card-subtitle class="pb-0">{{
                  $t('No tax entries added yet')
                }}</v-card-subtitle>
                <v-card-text>{{
                  $t('Click the button below to add your first tax')
                }}</v-card-text>
              </v-card>

              <div class="text-center mt-4">
                <v-btn
                  rounded
                  class="primary--text"
                  color="light300"
                  elevation="1"
                  @click="addTaxItem('tax')"
                >
                  <v-icon left small>fas fa-plus</v-icon>
                  {{ $t('Add Tax') }}
                </v-btn>
              </div>
            </div>
          </v-tab-item>

          <!-- Fees Tab -->
          <v-tab-item>
            <div class="py-6 px-2">
              <v-card
                v-if="feesOnly.length > 0"
                flat
                class="pa-4 mb-4 elevation-1"
              >
                <v-card-title class="text-subtitle-1 pa-0 mb-4 primary--text">
                  {{ $t('Fee Entries') }}
                </v-card-title>
                <div
                  v-for="(fee, ind) in feesOnly"
                  :key="`fee-${ind}`"
                  class="fee-item mb-4"
                >
                  <v-row class="align-center">
                    <v-col cols="5">
                      <v-autocomplete
                        v-model="fee.tax_type"
                        outlined
                        dense
                        hide-details
                        :items="feeTypesOnly"
                        label="Fee Type"
                        :rules="[required]"
                      />
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="fee.name"
                        outlined
                        dense
                        hide-details
                        :label="$t('Title')"
                        required
                        :rules="[required]"
                      />
                    </v-col>
                    <v-col cols="2"> </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="fee.amount"
                        type="number"
                        outlined
                        dense
                        hide-details
                        :label="$t('Amount')"
                        required
                        :rules="[required]"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        v-model="fee.units"
                        outlined
                        dense
                        hide-details
                        :items="taxUnits"
                        label="Unit"
                        :rules="[required]"
                      />
                    </v-col>
                    <v-col v-if="fee.units === 'fixed'" cols="5">
                      <v-select
                        v-model="fee.quantifier"
                        outlined
                        dense
                        hide-details
                        :items="taxQuantifier"
                        label="Quantifier"
                        :rules="[required]"
                      />
                    </v-col>
                    <v-col v-if="fee.units === 'percentage'" cols="5">
                      <v-select
                        v-model="fee.applicable_to"
                        outlined
                        dense
                        hide-details
                        multiple
                        small-chips
                        :items="taxApplicableTo"
                        label="Fee applicable to"
                        :rules="[required]"
                      />
                    </v-col>
                    <v-col cols="1">
                      <v-icon
                        class="ml-auto"
                        small
                        color="error"
                        @click.stop="removeTax(getOriginalIndex(fee))"
                        >$trash</v-icon
                      >
                    </v-col>
                    <v-col cols="12">
                      <v-radio-group
                        v-model="fee.tax_relation"
                        class="mt-2 mb-0 pa-0"
                        hide-details
                        row
                      >
                        <v-radio label="Belongs to Owner" value="owner" />
                        <v-radio label="Belongs to PMC" value="pmc" />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-divider
                    v-if="ind < feesOnly.length - 1"
                    class="my-3"
                  ></v-divider>
                </div>
              </v-card>

              <v-card v-else flat class="pa-4 elevation-1 text-center">
                <v-card-subtitle class="pb-0">{{
                  $t('No fee entries added yet')
                }}</v-card-subtitle>
                <v-card-text>{{
                  $t('Click the button below to add your first fee')
                }}</v-card-text>
              </v-card>

              <div class="text-center mt-4">
                <v-btn
                  rounded
                  class="primary--text"
                  color="light300"
                  elevation="1"
                  @click="addTaxItem('fee')"
                >
                  <v-icon left small>fas fa-plus</v-icon>
                  {{ $t('Add Fee') }}
                </v-btn>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>

        <v-divider class="mt-4"></v-divider>

        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn color="grey lighten-3" text class="px-5" @click="close">
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn color="primary" class="px-5 ml-3" type="submit" elevation="1">
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-sheet>
  </v-card>
</template>

<script>
import FormRules from 'components/mixins/form-rules-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import MultiPropertyPicker from 'components/multi-property-picker.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'TaxProfileEditModal',
  mixins: [FormRules, FormattersMixin],
  components: {
    MultiPropertyPicker,
  },
  props: ['taxProfile'],
  data() {
    return {
      activeTab: 0,
      taxProfiles: {
        name: null,
        taxes: [],
      },
      toRemove: [],
    }
  },
  computed: {
    ...mapState({
      taxDataSet: state => state.taxProfile.taxProfiles,
    }),
    taxesOnly() {
      return this.taxProfile.taxes.filter(
        tax => !tax.tax_type.toLowerCase().includes('fee')
      )
    },
    feesOnly() {
      return this.taxProfile.taxes.filter(tax =>
        tax.tax_type.toLowerCase().includes('fee')
      )
    },
    taxTypesOnly() {
      if (this.taxDataSet !== null) {
        const selectedTaxTypes = this.taxProfile.taxes.map(tax => tax.tax_type)

        return Object.keys(this.taxDataSet.tax_types)
          .filter(key => !key.toLowerCase().includes('fee'))
          .map(taxTypeKey => {
            return {
              text: taxTypeKey,
              value: taxTypeKey,
              disabled: selectedTaxTypes.includes(taxTypeKey),
            }
          })
      }
      return []
    },
    feeTypesOnly() {
      if (this.taxDataSet !== null) {
        const selectedTaxTypes = this.taxProfile.taxes.map(tax => tax.tax_type)

        return Object.keys(this.taxDataSet.tax_types)
          .filter(key => key.toLowerCase().includes('fee'))
          .map(taxTypeKey => {
            return {
              text: taxTypeKey,
              value: taxTypeKey,
              disabled: selectedTaxTypes.includes(taxTypeKey),
            }
          })
      }
      return []
    },
    taxTypes() {
      if (this.taxDataSet !== null) {
        const selectedTaxTypes = this.taxProfile.taxes.map(tax => tax.tax_type)

        return Object.keys(this.taxDataSet.tax_types).map(taxTypeKey => {
          return {
            text: taxTypeKey,
            value: taxTypeKey,
            disabled: selectedTaxTypes.includes(taxTypeKey),
          }
        })
      }
      return []
    },
    taxUnits() {
      return [
        { text: this.currencySign, value: 'fixed' },
        { text: '%', value: 'percentage' },
      ]
    },
    taxQuantifier() {
      if (this.taxDataSet !== null) {
        return Object.entries(this.taxDataSet.tax_quantifier).map(
          ([key, value]) => ({
            text: value,
            value: key,
          })
        )
      }
      return []
    },
    taxApplicableTo() {
      const baseTaxes = [
        { text: 'Accommodation Fare', value: 'AF' },
        { text: 'Cleaning', value: 'CF' },
      ]

      const selectedTaxTypes = this.taxProfile.taxes
        .filter(tax => tax.units === 'fixed')
        .map(tax => tax.tax_type)

      const additionalTaxes = Object.entries(this.taxDataSet.tax_types)
        .filter(([key]) => selectedTaxTypes.includes(key))
        .filter(([key]) => key.toLowerCase().includes('fee'))
        .map(([key, value]) => {
          return { text: key, value: value }
        })

      return baseTaxes.concat(additionalTaxes)
    },
  },
  methods: {
    ...mapActions('taxProfile', ['updateTaxProfile']),
    close() {
      this.$store.commit('hideModal')
    },
    removeListingId(id) {
      const index = this.taxProfile.listing_ids.indexOf(id)
      if (index >= 0) {
        // Create a new array to ensure reactivity
        const newListingIds = [...this.taxProfile.listing_ids]
        newListingIds.splice(index, 1)
        this.$set(this.taxProfile, 'listing_ids', newListingIds)
      }
    },
    getOriginalIndex(taxItem) {
      return this.taxProfile.taxes.findIndex(tax => tax === taxItem)
    },
    addTaxItem(type) {
      // Default tax type to ensure it goes to the right tab
      let defaultTaxType = ''

      if (type === 'fee') {
        // Get available fee types (not already selected)
        const availableFeeTypes = this.feeTypesOnly
          .filter(item => !item.disabled)
          .map(item => item.value)

        // Select the first available fee type, or use a default if none are available
        defaultTaxType =
          availableFeeTypes.length > 0 ? availableFeeTypes[0] : 'Resort Fee'
      } else {
        // Get available tax types (not already selected)
        const availableTaxTypes = this.taxTypesOnly
          .filter(item => !item.disabled)
          .map(item => item.value)

        // Select the first available tax type, or use a default if none are available
        defaultTaxType =
          availableTaxTypes.length > 0 ? availableTaxTypes[0] : 'VAT'
      }

      const newItem = {
        tax_type: defaultTaxType,
        units: '',
        quantifier: 'per_stay',
        applicable_to: [],
        tax_relation: 'pmc',
        inclusive: false,
        max_days: null,
      }

      this.taxProfile.taxes.push(newItem)

      // Switch to appropriate tab after adding
      if (type === 'tax') {
        this.activeTab = 1
      } else if (type === 'fee') {
        this.activeTab = 2
      }
    },
    removeTax(idx) {
      if (idx < 0 || idx >= this.taxProfile.taxes.length) {
        console.error(`Invalid tax index: ${idx}`)
        return
      }

      const taxToRemove = this.taxProfile.taxes[idx]

      // Add the ID to the toRemove array if it exists (for existing taxes)
      if (taxToRemove.id) {
        this.toRemove.push(taxToRemove.id)
      }

      const removedTaxType = taxToRemove.tax_type
      const removedTaxKey = this.taxDataSet?.tax_types?.[removedTaxType]

      // Remove the tax from the array
      this.taxProfile.taxes.splice(idx, 1)

      // Clean up any references to this tax in applicable_to fields
      if (removedTaxKey) {
        this.taxProfile.taxes.forEach(tax => {
          if (
            tax.applicable_to &&
            Array.isArray(tax.applicable_to) &&
            tax.applicable_to.includes(removedTaxKey)
          ) {
            tax.applicable_to = tax.applicable_to.filter(
              applicable => applicable !== removedTaxKey
            )
          }
        })
      }
    },
    async updateTaxProfile() {
      if (this.$refs.editTaxProfile.validate()) {
        this.$store.commit('updateLoading', true)
        await this.$store
          .dispatch('taxProfile/updateTaxProfile', {
            tax_profile: {
              id: this.taxProfile.id,
              name: this.taxProfile.name,
              taxes: this.taxProfile.taxes,
              tax_relation: this.taxProfile.tax_relation,
              listing_ids: this.taxProfile.listing_ids || [],
            },
            removed: this.toRemove,
          })
          .finally(() => {
            this.$store.commit('updateLoading', false)
            this.toRemove = []
          })
        this.close()
      }
    },
  },
  created() {
    // Initialize listing_ids if it doesn't exist
    if (!this.taxProfile.listing_ids) {
      this.$set(this.taxProfile, 'listing_ids', [])
    }
  },
}
</script>

<style scoped>
.close-button {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.charge-profile-modal {
  border-radius: 8px;
}

.tax-item,
.fee-item {
  transition: all 0.2s ease;
  border-radius: 4px;
}

.tax-item:hover,
.fee-item:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
</style>
