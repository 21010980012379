<template>
  <v-autocomplete
    v-model="model"
    hide-details
    flat
    solo
    dense
    no-filter
    outlined
    clearable
    :items="combinedResults"
    :search-input.sync="searchValue"
    :loading="isLoading"
    item-text="displayName"
    return-object
    :placeholder="$t('Search listings and reservations')"
    @change="handleSelection"
    @update:search-input="debouncedSearch"
  >
    <template #item="{ item }">
      <!-- Divider for Categories -->
      <div
        v-if="item.isDivider"
        :class="`w-100 text-center font-weight-medium mb-4 py-1 text-body-2 white--text ${
          item.color || 'info'
        } darken-1`"
      >
        {{ item.label }}
      </div>

      <!-- Listing Item -->
      <div
        v-else-if="item.type === 'listing'"
        :class="`d-flex align-center pa-3 listing-item ${
          item.lightText ? 'grey--text' : ''
        }`"
      >
        <v-avatar tile rounded class="mr-3">
          <v-img :src="item.image || item.picture || placeholderImg" />
        </v-avatar>
        <div>
          <div class="d-flex align-center">
            <div class="font-weight-medium">{{ item.nickname }}</div>
            <v-chip
              v-if="item.listed"
              label
              class="mx-2"
              x-small
              color="green lighten-3"
            >
              {{ $t('Listed') }}
            </v-chip>
            <v-chip
              v-if="!item.listed"
              label
              class="mx-2"
              x-small
              color="red lighten-3"
            >
              {{ $t('UnListed') }}
            </v-chip>
            <v-chip
              v-if="!item.active"
              label
              class="mx-2"
              x-small
              color="grey lighten-2"
            >
              {{ $t('Disabled') }}
            </v-chip>
            <v-chip
              v-if="item.second_guesty_id"
              label
              class="mx-2"
              x-small
              color="blue lighten-4"
            >
              {{ $t('Dup') }}
            </v-chip>
            <v-chip
              v-if="!item.second_guesty_id"
              label
              class="mx-2"
              x-small
              color="purple lighten-4"
            >
              {{ $t('Main') }}
            </v-chip>
            <v-chip
              v-if="item.is_multi_unit"
              label
              class="mx-2"
              x-small
              color="cyan lighten-4"
            >
              {{ $t('Multi') }}
            </v-chip>
            <v-chip
              v-if="item.listing_type === 'hotel'"
              label
              class="mx-2"
              x-small
              color="purple lighten-4"
            >
              {{ $t('Complex') }}
            </v-chip>
            <v-chip
              v-if="item.stage === 'setup'"
              label
              class="mx-2"
              x-small
              color="orange lighten-4"
            >
              {{ $t('Setup') }}
            </v-chip>
          </div>
          <div class="text-caption">{{ item.title }}</div>
          <div class="text-caption">
            <span>{{ item.beds }}</span>
            <v-icon class="mx-1" small>$bedroom</v-icon>
            <span>{{ item.baths }}</span>
            <v-icon small>$bath</v-icon>
          </div>
        </div>
      </div>

      <!-- Reservation Item -->
      <div
        v-else-if="item.type === 'reservation'"
        class="pa-3 reservation-item"
      >
        <div class="font-weight-medium mb-1">
          {{ item.guest_name }}
          <span v-if="item.confirmation_code" class="text-caption font-italic">
            ({{ item.confirmation_code }})
          </span>
        </div>
        <div class="text-caption mb-1">
          {{ parseDate(item.reservation_check_in, 'MMM DD, YYYY') }} -
          {{ parseDate(item.reservation_check_out, 'MMM DD, YYYY') }}
        </div>
        <div class="text-caption d-flex align-center">
          <v-chip
            small
            label
            class="mr-2"
            :color="resStatusColor(item.reservation_status)"
          >
            {{ item.reservation_status }}
          </v-chip>
          <span>{{ item.listing }}</span>
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import debounce from 'lodash/debounce'
import placeholderImg from '@/assets/images/home.png'

export default {
  name: 'GlobalSearch',
  mixins: [CommonFunctions],

  data() {
    return {
      model: null,
      searchValue: null,
      listings: [],
      reservations: [],
      isLoading: false,
      placeholderImg,
      debouncedSearch: debounce(this.performSearch, 500),
    }
  },

  computed: {
    combinedResults() {
      const results = []

      if (this.listings.length) {
        // Categorize listings
        const categories = {
          main: [],
          multiUnits: [],
          units: [],
          dups: [],
          setup: [],
          disabled: [],
        }

        this.listings.forEach(listing => {
          if (!listing.active) {
            categories.disabled.push({
              ...listing,
              type: 'listing',
              displayName: listing.nickname,
              lightText: true,
            })
          } else if (listing.is_multi_unit) {
            categories.multiUnits.push({
              ...listing,
              type: 'listing',
              displayName: listing.nickname,
            })
          } else if (listing.container_multi_id) {
            categories.units.push({
              ...listing,
              type: 'listing',
              displayName: listing.nickname,
            })
          } else if (listing.second_guesty_id) {
            categories.dups.push({
              ...listing,
              type: 'listing',
              displayName: listing.nickname,
              lightText: true,
            })
          } else if (listing.stage === 'setup') {
            categories.setup.push({
              ...listing,
              type: 'listing',
              displayName: listing.nickname,
              lightText: true,
            })
          } else {
            categories.main.push({
              ...listing,
              type: 'listing',
              displayName: listing.nickname,
            })
          }
        })

        // Add main listings
        if (categories.main.length) {
          results.push({ isDivider: true, label: this.$t('Listings') })
          results.push(...categories.main)
        }

        // Add multi units
        if (categories.multiUnits.length) {
          results.push({
            isDivider: true,
            label: this.$t('Multi units'),
            color: 'green',
          })
          results.push(...categories.multiUnits)
        }

        // Add units
        if (categories.units.length) {
          results.push({
            isDivider: true,
            label: this.$t('Units'),
            color: 'teal',
          })
          results.push(...categories.units)
        }

        // Add duplicates
        if (categories.dups.length) {
          results.push({
            isDivider: true,
            label: this.$t('Duplicates'),
            color: 'amber',
          })
          results.push(...categories.dups)
        }

        // Add setups
        if (categories.setup.length) {
          results.push({
            isDivider: true,
            label: this.$t('Setups'),
            color: 'indigo',
          })
          results.push(...categories.setup)
        }

        // Add disabled
        if (categories.disabled.length) {
          results.push({
            isDivider: true,
            label: this.$t('Disabled'),
            color: 'blue-grey',
          })
          results.push(...categories.disabled)
        }
      }

      // Add reservations at the end
      if (this.reservations.length) {
        results.push({
          isDivider: true,
          label: this.$t('Reservations'),
        })
        results.push(
          ...this.reservations.map(reservation => ({
            ...reservation,
            type: 'reservation',
            displayName: reservation.guest_name,
          }))
        )
      }

      return results
    },
  },

  methods: {
    ...mapActions('reservation', ['searchReservations']),

    async performSearch() {
      if (!this.searchValue || this.searchValue.length < 2) {
        this.listings = []
        this.reservations = []
        return
      }

      this.isLoading = true

      try {
        // Search listings from store
        this.listings = this.$store.state.listingsIndex.filter(
          listing =>
            listing.nickname
              ?.toLowerCase()
              .includes(this.searchValue.toLowerCase()) ||
            listing.title
              ?.toLowerCase()
              .includes(this.searchValue.toLowerCase())
        )

        // Search reservations
        const reservations = await this.searchReservations({
          term: this.searchValue,
        })
        this.reservations = reservations || []
      } catch (error) {
        console.error('Search failed:', error)
      } finally {
        this.isLoading = false
      }
    },

    handleSelection(item) {
      if (!item) return

      if (item.type === 'listing') {
        const path = this.isInvestor
          ? `/dashboard/property/${item.id}`
          : `/dashboard/edit/${item.id}`
        this.$router.push(path)
      } else if (item.type === 'reservation') {
        this.$router.push(
          `/dashboard/reservation/${item.reservation_guesty_id}`
        )
      }

      // Clear the input and model after navigation
      this.$nextTick(() => {
        this.model = null
        this.searchValue = null
      })
    },

    resStatusColor(status) {
      const colors = {
        confirmed: 'green lighten-4',
        canceled: 'red lighten-4',
        inquiry: 'blue lighten-4',
        declined: 'grey lighten-4',
      }
      return colors[status?.toLowerCase()] || 'grey lighten-4'
    },
  },
}
</script>

<style lang="scss" scoped>
:deep(.v-list-item) {
  padding: 0;
}
</style>
