import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import customAxios from '@/axios/config'
import moment from 'moment-timezone'
import qs from 'qs'
import {
  cloneDeep,
  compact,
  compose,
  get,
  map,
  pickBy,
  toNumber,
  uniq,
} from 'lodash/fp'
import { find, findIndex, isEmpty, unionBy } from 'lodash'
import { router } from './route'
import iotModule from './modules/IOTModule'
import listingInfoDefinitionsModule from './modules/ListingInfoDefinitionsModule'
import listingTaskModule from './modules/ListingTaskModule'
import listingSetupModule from './modules/ListingSetupModule'
import ListingTaskCatalogItemModule from './modules/ListingTaskCatalogItemModule'
import ReviewsModule from './modules/ReviewsModule'
import CheckoutModule from './modules/CheckoutModule'
import StoreAdminModule from './modules/StoreAdminModule'
import modalModule from './modules/modalModule'
import userModule from './modules/user-module'
import ProjectsModule from './modules/projects-module'
import userGroupsModule from './modules/UserGroupsModule'
import kpisModule from './modules/KpisModule'
import calendarModule from './modules/CalendarModule'
import AppModule from './modules/AppModule'
import NotificationsModule from './modules/NotificationsModule'
import ExpenseModule from './modules/ExpenseModule'
import InventoryModule from './modules/InventoryModule'
import ListingsModule from './modules/ListingsModule'
import ReservationModule from './modules/reservationModule'
import StripeModule from './modules/StripeModule'
import DashboardModule from './modules/DashboardModule'
import { MAX_SC_TASKS_RESULT } from './consts'
import WarrantyModule from './modules/WarrantyModule'
import common_functions from './components/mixins/common_functions'
import ShoppingListModule from './modules/ShoppingListModule'
import StoragesModule from './modules/StoragesModule'
import listingCalendarModule from './modules/ListingCalendarModule'
import TaskimModule from './modules/TaskimModule'
import EstimatesModule from './modules/EstimatesModule'
import HousekeepingModule from './modules/HousekeepingModule'
import guestModule from './modules/GuestModule'
import Toaster from '@/utils/toaster'
import RegionsModule from './modules/RegionsModule'
import ZonesModule from './modules/ZonesModule'
import ListingReportModule from './modules/ListingReportModule'
import { PRIORITIES_ORDER } from '@/consts'
import apiAxios, { autoCancelToken } from '@/axios/config'
import BankAccountsModule from '@/modules/BankAccountsModule'
import businessModelModule from '@/modules/businessModelModule'
import TaxProfileModule from './modules/TaxProfileModule'
import PricingStrategyModule from './modules/PricingStrategyModule'
import SmartRuleModule from './modules/SmartRuleModule'
import SettingsModule from './modules/SettingsModule'
import BillingModule from './modules/BillingModule'
import ConversationsModule from './modules/ConversationsModule'
import TenantsModule from './modules/TenantsModule'
import LosSettingModule from './modules/LosSettingModule'
import ExpenseTypesModule from '@/modules/ExpenseTypesModule'
import FeedModule from '@/modules/FeedModule'
import SeamIotModule from '@/modules/SeamIotModule'
import RatePlanModule from './modules/RatePlanModule'
import TemplatesModule from './modules/TemplatesModule'

const projectNames = compose(compact, uniq, map('project_name'))
Vue.use(Vuex)
export const errorStatus = get('response.status')

const getPriority = priority =>
  priority ? PRIORITIES_ORDER[priority.toLowerCase()] : PRIORITIES_ORDER.none

const getIsAllDay = scheduled_at =>
  new Date(scheduled_at).getUTCHours() < 4 ||
  new Date(scheduled_at).getUTCHours() > 22

const state = {
  headerProgressBar: false,
  activeCommUsers: [],
  pricings: [],
  requestsPagination: null,
  chatMobileView: 'conversations',
  chatConversations: [],
  chatStats: null,
  chatType: 'sales',
  chosenChatConversation: null,
  currentListingSetupStats: null,
  taskCalendarSuggestions: [],
  pushedMessage: null,
  preMadeMessages: [],
  dailySchedule: [],
  showLtDailyPop: false,
  ltDailyPopTasks: [],
  showPreMade: false,
  showTagPop: false,
  conversationTags: [],
  chosenCity: null,
  mapContractorId: null,
  communicationWarnings: [],
  currentInvestmentListing: null,
  fetchingChatConversation: false,
  investmentInfo: null,
  listingTasksPagination: null,
  pricingPagniation: null,
  autoRulesPagination: {},
  scopeItems: [
    { text: 'Construction', value: 'construction' },
    { text: 'Design', value: 'design' },
    { text: 'Estimate Construction', value: 'estimate_construction' },
    { text: 'Estimate Design', value: 'estimate_design' },
    { text: 'Estimate Interior Design', value: 'estimate_interior_design' },
    { text: 'Estimate Landscape', value: 'estimate_landscape' },
    { text: 'Estimate Outdoor Amenities', value: 'estimate_outdoor_amenities' },
    { text: 'Permits', value: 'permits' },
    { text: 'Realestate', value: 'realestate' },
    { text: 'Setup', value: 'setup' },
    { text: 'Channel', value: 'channel' },
  ],
  conversationsPagination: null,
  listingTaskCalDate: '',
  parkings: null,
  currentClockedInCleaning: null,
  ltdContractorId: null,
  ltdChangedDate: null,
  ltdSelectedIDS: new Set(),
  guests: [],
  tasksCalendarListingResources: [],
  tasksCalendarContractorResources: [],
  listingTaskCreationInfo: null,
  chosenVideo: null,
  autoListingTaskRules: null,
  autoMessageRules: [],
  bookingEngineSettings: null,
  autoRulesSelectedIDS: [],
  optionalListings: [],
  listingTasksStats: null,
  currentListingSetup: null,
  justMovedTaskId: null,
  chosenInventoryItem: null,
  chosenListingSetupTask: null,
  currentListingSetupListNames: null,
  currentListingSetupTasks: null,
  currentCompanyTasks: null,
  listingStaticTasks: null,
  inventoryItems: [],
  itemModal: null,
  listingTasksMapCenter: {},
  listingTaskSubTopics: {},
  listingTaskTotalCount: [],
  pendingAccept: [],
  catagoriesOptions: [],
  recentExpensesRules: [],
  companyActivity: null,
  transactionsSummary: {},
  recentListingIncomes: [],
  chosenTransaction: null,
  marketingActions: null,
  lastRequestFilter: {},
  currentGuest: null,
  guests_total: 0,
  cleaners: [],
  listingSetups: [],
  listingInfoItems: null,
  currentBankTransactions: [],
  currentBankTransactionsOptions: [],
  garbage_list: {},
  special_list: {},
  check_ins_list: [],
  currentlistingTask: null,
  currentPricingMonthly: null,
  currentPricingYearly: null,
  pricingSetings: {},
  currentPricing: null,
  listingsIndex: [],
  pricingStrategies: [],
  pricingEngineOptions: [],
  lastReservationsFilter: {},
  experienceRequests: [],
  listing_tasks_calender: {},
  consumables: [],
  requests: [],
  pricingReservationsStats: null,
  editExpense: null,
  conversationTagsObjects: [],
  companyUsers: [],
  experiences: [],
  todayChecks: null,
  trackedListingTask: null,
  listingTasksPaymentsStats: null,
  listingTasksPaymentsTasks: null,
  settings: {},
  ota_languages: [],
  AiConfigs: {},
  aiLogs: {},
  currentOrdersDate: null,
  projectStats: null,
  rulesWarnings: [],
  showListingInfoPop: false,
  listingFieldManage: [],
  listingFieldManageStats: {},
  chosenInfoItem: null,
  touristsData: null,
  hoverOnTask: null,
  currentInvestment: {
    active: true,
    airbnb_comps: [],
    sales_comps: [],
    units: [],
    yearly_expenses: {
      association: 0,

      Electricy: 0,
      Water: 0,
      Internet: 0,
      Repairs: 0,
    },
  },
  companyShifts: [],
  emptyInvestment: {
    active: true,
    airbnb_comps: [],
    sales_comps: [],
    units: [],
    yearly_expenses: {
      association: 0,
      Electricy: 0,
      Water: 0,
      Internet: 0,
      Landscape: 0,
      Pool: 0,
      Repairs: 0,
    },
  },
  currentListing: {
    accounting_data: {
      channels: {},
      expenses_sum: 0,
      need_to_stransfer: 0,
      transfered_sum: 0,
    },
    calendar: [],
    guesty_id: '',
    id: 0,
    picture: '',
    recent_expenses: [],
    recent_reservations: [],
    address: '',
    chosenPayment: null,
    recentPayments: [],
    monthly_stats: {},
    yearly_stats: {
      expenses_data: {},
      month_data: {
        names: [],
        revenue: [],
      },
    },
  },
  inventoryPagination: null,
  managedReservations: [],
  totalManagedReservations: {},
  managedReservationsStats: {},
  managedReservationsInfo: {},
  currentEditListing: null,
  changeEditListing: null,
  display: 'listings',
  recentExpenses: [],
  expenseModal: null,
  recentPayments: [],
  accountingDataMonthly: null,
  accountingDataFetching: false,
  lastExpensesFilter: {},
  isExpensesLoading: false,
  accountingData: null,
  accountingDataYearly: null,
  currentAccountingView: 'monthly',
  listingTasks: [],
  pendingListingTasks: [],
  listingTasksLoaded: false,
  pendingListingTasksLoaded: false,
  listingInfoWarnings: [],
  loading: false,
  listingLoading: false,
  sendMessageLoading: false,
  user: null,
  chosenExpense: null,
  chosenConversation: null,
  chosenChatReservation: null,
  listingTaskLoading: false,
  contractorPaymentsType: '',
  conversationLoading: false,
  loadingGuestExpInfo: false,
  contractorPaymentsDateRange: {
    from: new Date(new Date().setDate(new Date().getDate() - 7))
      .toISOString()
      .substr(0, 10),
    to: new Date().toISOString().substr(0, 10),
  },
  marketplace: [],
  contractorPaymentsDetails: {},
  reservationsPagination: {
    page: 1,
    itemsPerPage: 10,
    reservations_count: 0,
  },
  whatsappTemplates: null,
  serviceCallModalOpened: false,
  serviceCallModalPayload: null,
  serviceCallAISuggestion: false,
  aiPolicy: {},
  monthSeasonFactor: {},
  coupons: [],
  couponsPagination: null,
  couponsRules: [],
  couponsRulesPagination: null,
  aiAgentVersions: [],
  aiAgentVersionsPagination: null,
  aiVersionControls: [],
  aiVersionControlsPagination: null,
  listingsNoScope: [],
}
const mutations = {
  updateHeaderProgressBar(state, loading) {
    state.headerProgressBar = loading
  },
  updateMarketplace(state, data) {
    state.marketplace = data
  },
  updateListingTaskLoading(state, loading) {
    state.listingTaskLoading = loading
  },
  updateTaskCalendarSuggestions(state, data) {
    state.taskCalendarSuggestions = data
  },
  updateTasksCalendarResources(state, data) {
    state.tasksCalendarListingResources = data.listings_resources
    state.tasksCalendarContractorResources = data.contractors_resources
  },
  updateListingTaskSubTopics(state, data) {
    state.listingTaskSubTopics = data
  },
  updateListingTaskTotalCount(state, data) {
    state.listingTaskTotalCount = data
  },
  updatePendingAccept(state, data) {
    state.pendingAccept = data
  },
  updateActiveCommUsers(state, data) {
    state.activeCommUsers = data
  },
  updateShowLtDailyPop(state, data) {
    state.showLtDailyPop = data
  },
  updateLtDailyPopTasks(state, data) {
    state.ltDailyPopTasks = data
  },
  updatePushedMessage(state, data) {
    state.pushedMessage = data
  },
  updateShowPreMade(state, data) {
    state.showPreMade = data
  },
  updateTrackedListingTask(state, data) {
    state.trackedListingTask = data
  },
  updateChatMobileView(state, data) {
    state.chatMobileView = data
  },
  updateChosenInfoItem(state, data) {
    state.chosenInfoItem = data
  },
  updateShowTagPop(state, data) {
    state.showTagPop = data
  },
  updateConversationTags(state, data) {
    state.conversationTags = data
  },
  updateConversationTagsObjects(state, data) {
    state.conversationTagsObjects = data
  },
  updateJustMovedTaskId(state, data) {
    state.justMovedTaskId = data
  },
  updateChosenConversation(state, data) {
    state.chosenConversation = data
  },
  updateLtdContractorId(state, data) {
    state.ltdContractorId = data
  },
  updateLtdChangedDate(state, data) {
    state.ltdChangedDate = data
  },
  updateLtdSelectedIDS(state, data) {
    state.ltdSelectedIDS = data
  },
  updateAutoRulesSelectedIDS(state, data) {
    state.autoRulesSelectedIDS = data
  },
  updateHoverOnTask(state, data) {
    state.hoverOnTask = data
  },
  updateCurrentInvestmentListing(state, data) {
    state.currentInvestmentListing = data
  },
  updateCommunicationWarnings(state, data) {
    state.communicationWarnings = data
  },
  updateRulesWarnings(state, data) {
    state.rulesWarnings = data
  },
  updateChosenInventoryItem(state, data) {
    state.chosenInventoryItem = data
  },
  updateListingInfoItems(state, data) {
    state.listingInfoItems = data
  },
  updateListingInfoWarnings(state, data) {
    state.listingInfoWarnings = data
  },
  updateMapContractorId(state, data) {
    state.mapContractorId = data
  },
  updateCurrentPricing(state, data) {
    state.currentPricing = data
  },
  updateInventoryItems(state, data) {
    state.inventoryItems = data
  },
  updateChosenVideo(state, data) {
    state.chosenVideo = data
  },
  updateChatConversations(state, data) {
    state.chatConversations = data
  },
  updateChatReservation(state, data) {
    state.chosenChatReservation = data
  },
  appendChatConversations(state, data) {
    const chats = state.chatConversations.filter(c => c.id !== data.id)
    state.chatConversations = [data, ...chats]
  },
  updateCatagoriesOptions(state, data) {
    state.catagoriesOptions = data
  },
  updateInventoryPagination(state, data) {
    state.inventoryPagination = data
  },
  updateListingTaskCalDate(state, data) {
    state.listingTaskCalDate = data
  },
  updateMapCenter(state, data) {
    state.listingTasksMapCenter = data
  },
  updateCompanyActivity(state, data) {
    state.companyActivity = data
  },
  updateEditExpense(state, data) {
    state.editExpense = data
  },
  updateCompanyUsers(state, data) {
    state.companyUsers = data
  },
  updateListingTasksStats(state, data) {
    state.listingTasksStats = data
    state.projectNames = data['lt_projects']
  },
  updateListingTasksPaymentsTasks(state, data) {
    state.listingTasksPaymentsTasks = data
  },
  updateListingTasksPaymentsStats(state, data) {
    state.listingTasksPaymentsStats = data
  },
  updateProjectsStats(state, data) {
    state.projectStats = data
  },
  updateListingTaskCreationInfo(state, data) {
    state.listingTaskCreationInfo = data
  },
  updateCompanyShifts(state, data) {
    state.companyShifts = data
  },
  updateCurrentListingSetup(state, data) {
    state.currentListingSetup = data
  },
  updateCurrentListingSetupTasks(state, data) {
    state.currentListingSetupTasks = data
  },
  updateCurrentCompanyTasks(state, data) {
    state.currentCompanyTasks = data
  },
  updateSingleConversation(state, data) {
    const chatIndex = findIndex(state.chatConversations, { id: data.id })
    state.chatConversations.splice(chatIndex, 1, {
      ...state.chatConversations[chatIndex],
      ...data,
    })
  },
  updateCurrentCompanyTasksPart(state, data) {
    state.currentCompanyTasks.tasks = data
  },
  updateListingStaticTasks(state, data) {
    state.listingStaticTasks = data
  },
  updateCurrentListingSetupListNames(state, data) {
    state.currentListingSetupListNames = data
  },
  updateCurrentListingSetupStats(state, data) {
    state.currentListingSetupStats = data
  },
  updateListingTasks(state, data) {
    state.listingTasks = data.map(t => ({
      ...t,
      priorityOrder: getPriority(t.priority),
      allDay: getIsAllDay(t.scheduled_at),
    }))
    state.listingTasksLoaded = true
  },
  updatePendingListingTasks(state, data) {
    state.pendingListingTasks = data.map(t => ({
      ...t,
      priorityOrder: getPriority(t.priority),
      allDay: getIsAllDay(t.scheduled_at),
    }))
    state.pendingListingTasksLoaded = true
  },
  updatelistingSetups(state, data) {
    state.listingSetups = data
  },
  updateSinglelistingSetups(state, data) {
    const ind = state.listingSetups.findIndex(t => t.id === data.id)
    Vue.set(state.listingSetups, ind, data)
  },
  updatePricingYearlyStats(state, data) {
    state.currentPricingYearly = data
  },
  updateChosenListingSetupTask(state, data) {
    state.chosenListingSetupTask = data
  },
  updateChosenWarning(state, data) {
    state.chosenListingWarning = data
  },
  updateChosenTransaction(state, data) {
    state.chosenTransaction = data
  },
  updateUser(state, user) {
    state.user = user
  },
  updatePricingMonthlyStats(state, data) {
    state.currentPricingMonthly = data
  },
  updatePricingReservationsStats(state, data) {
    state.pricingReservationsStats = data
  },
  updatePricings(state, data) {
    state.pricings = data['pricings']
    state.pricingSetings = data['pricing_setting']
  },
  updateExperienceRequests(state, experienceRequests) {
    let cloned = cloneDeep(experienceRequests)
    state.experienceRequests = cloned
  },
  updatePricingSetting(state, ps) {
    state.pricingSetings = ps
  },

  updateLastReservationsFilter(state, ps) {
    state.lastReservationsFilter = ps
  },
  updateLastExpensesFilter(state, ps) {
    state.lastExpensesFilter = ps
  },
  updateFetchingExpenses(state, value) {
    state.isExpensesLoading = value
  },
  updateGuests(state, data) {
    state.guests = data.guests
    state.guests_total = data.guests_total
  },
  updateCurrentGuest(state, data) {
    state.currentGuest = data
  },
  updateChatStats(state, data) {
    if (data) {
      state.chatStats = data
    }
  },
  updateChatType(state, type) {
    state.chatType = type
  },
  updateExperiences(state, data) {
    state.experiences = data
  },
  updateTransactionsSummary(state, data) {
    state.transactionsSummary = data
  },
  updateTransactionOptions(stats, data) {
    state.currentBankTransactionsOptions = data
  },
  updateTransactions(stats, data) {
    state.currentBankTransactions = data
  },
  updateManagedReservations(
    state,
    { reservations, totals, stats, show_verification_status }
  ) {
    state.managedReservations = reservations
    state.totalManagedReservations = totals
    state.managedReservationsStats = stats
    state.managedReservationsInfo = { show_verification_status }
  },
  updateSettings(state, data) {
    state.settings = data
  },
  setOtaLanguages(state, data) {
    state.ota_languages = data
  },
  updateAiConfigs(state, data) {
    state.AiConfigs = data
  },
  updateAiLogs(state, data) {
    state.aiLogs = data
  },
  updateParkings(state, data) {
    state.parkings = data
  },
  updateCurrentClockedInCleaning(state, data) {
    state.currentClockedInCleaning = data
  },
  destroyCurrentEditListing(state) {
    state.currentEditListing = null
  },
  updateTouristData(state, data) {
    state.touristsData = data
  },
  updateCurrentListingTask(state, currentlistingTask) {
    state.currentlistingTask = currentlistingTask
  },
  updateListingTask(state, task) {
    const ind = state.listingTasks.findIndex(t => t.id === task.id)
    state.listingTasks[ind] = {
      ...task,
      priorityOrder: getPriority(task.priority),
      allDay: getIsAllDay(task.scheduled_at),
    }
  },
  updateTodayChecks(state, data) {
    state.todayChecks = data
  },
  updateShowListingInfoPop(state, data) {
    state.showListingInfoPop = data
  },
  updateInvestmentInfo(state, investmentInfo) {
    state.investmentInfo = investmentInfo
  },

  updateGenericLists(state, lists) {
    if (lists.garbage_list) {
      state.garbage_list = lists.garbage_list
    }
    if (lists.special_list) {
      state.special_list = lists.special_list
    }
    if (lists.check_ins_list) {
      state.check_ins_list = lists.check_ins_list
    }
  },
  updateListingFieldManage(state, data) {
    state.listingFieldManage = data
  },
  updateListingFieldManageStats(state, stats) {
    state.listingFieldManageStats = stats
  },
  updateRequestsPagination(state, stats) {
    state.requestsPagination = stats
  },
  updateOptionalListings(state, stats) {
    state.optionalListings = stats
  },
  updateAutoListingTaskRules(state, stats) {
    state.autoListingTaskRules = stats
  },
  updateAutoMessageRules(state, stats) {
    state.autoMessageRules = stats
  },
  updateBookingEngineSettings(state, stats) {
    state.bookingEngineSettings = stats
  },
  updateMarketingActions(state, actions) {
    state.marketingActions = actions
  },
  emptyInvestment(state) {
    state.currentInvestment = state.emptyInvestment
  },
  updateCleaners(state, cleaners) {
    state.cleaners = cleaners
  },
  updateCurrentInvestments(state, investment) {
    state.currentInvestment = investment
    state.currentInvestment.airbnb_comps = investment.airbnb_comps
    state.currentInvestment.sales_comps = investment.sales_comps
    state.currentInvestment.units = investment.units
  },
  updatelastRequestFilter(state, lastRequestFilter) {
    state.lastRequestFilter = lastRequestFilter
  },
  updateManagmentStats(state, stats) {
    state.recentExpenses = stats['recent_expenses']
    state.recentPayments = stats['recent_payments']
  },
  updateChosenExpense(state, chosenExpense) {
    state.chosenExpense = chosenExpense
  },
  updateMonthSeasonFactor(state, data) {
    state.monthSeasonFactor = data
  },
  changeListing(state, currentListing) {
    $('html,body').animate({ scrollTop: 0 }, 0)
    state.currentListing = currentListing
    state.recentExpenses = currentListing['recent_expenses']
    state.requests = currentListing['requests']
    state.recentPayments = currentListing['recent_payments']
    state.recentListingIncomes = currentListing['recent_listing_incomes']
    state.currentPricing = currentListing['pricing']
    state.investmentInfo = currentListing['investment_data']
    state.display = 'listing'
  },
  changeEditListing(state, currentEditListing) {
    state.changeEditListing = null
    let cloned = cloneDeep(currentEditListing)
    state.recentPayments = currentEditListing['recent_payments']
    state.recentListingIncomes = currentEditListing['recent_listing_incomes']
    state.currentEditListing = cloned
    state.currentListing = cloned
    state.display = 'edit-listing'
  },
  updateCurrentEditListing(state, listing) {
    let cloned = cloneDeep(listing)
    state.currentEditListing = cloned
    state.currentListing = cloned
  },
  setListingProfileImage(state, imageURL) {
    state.currentEditListing['picture'] = imageURL
  },
  updateListings(state, listings) {
    state.listings = listings
  },
  updateSingleListing(state, { data }) {
    const index = state.listings.listings.findIndex(l => l.id === data.id)
    Vue.set(state.listings.listings, index, data)
  },
  updateListingsPicker(state, listings) {
    state.listingsIndex = listings
  },
  updatePricingEngineOptions(state, options) {
    state.pricingEngineOptions = options
  },
  updatePricingStrategiesList(state, list) {
    state.pricingStrategies = list
  },
  updateConversationsPagination(state, pagiInfo) {
    state.conversationsPagination = pagiInfo
  },
  updatePreMadeMessages(state, data) {
    state.preMadeMessages = data
  },
  updateDailySchedule(state, data) {
    state.dailySchedule = data
  },
  updateListingTasksPagination(state, pagiInfo) {
    state.listingTasksPagination = pagiInfo
  },
  updatePricingPagniation(state, pagiInfo) {
    state.pricingPagniation = pagiInfo
  },
  updateAutoRulesPagination(state, pagiInfo) {
    state.autoRulesPagination = pagiInfo
  },
  updateLoading(state, action) {
    state.loading = action
  },
  updateListingIntegrations(state, integrations) {
    state.currentListing['integrations'] = integrations
  },
  updateListingLoading(state, action) {
    state.listingLoading = action
  },
  updateSendMessageLoading(state, action) {
    state.sendMessageLoading = action
  },

  updateRecentPayments(state, action) {
    state.recentPayments = action
  },
  chatMessageOptimisticUpdate(state, action) {
    state.chosenChatConversation = {
      ...state.chosenChatConversation,
      thread_plus_dvr_log: [
        ...(state.chosenChatConversation?.thread_plus_dvr_log || []),
        {
          by: 'host',
          body: action.message,
          module: action.module,
          msg_type: action.module,
          rep_name: action.rep_name,
          sent_at: new Date().toString(),
        },
      ],
    }
  },
  updateDisplay(state, display) {
    jQuery('html,body').animate({ scrollTop: 0 }, 0)
    state.display = display
  },
  updateMonthlyStats(state, stats) {
    let cloned = cloneDeep(state.currentListing)
    cloned.monthly_stats = stats
    cloned.unit_stats = stats['unit_stats']
    state.currentListing = cloned
  },
  updateYealryStats(state, stats) {
    let cloned = cloneDeep(state.currentListing)
    cloned.yearly_stats = stats
    state.currentListing = cloned
  },
  updateChosenChatConversation(state, data) {
    state.chosenChatConversation = { ...state.chosenChatConversation, ...data }
  },
  updateFetchingChatConversation(state, data) {
    state.fetchingChatConversation = data
  },
  updateChosenChatConversationThread(state, stats) {
    let cloned = cloneDeep(state.chosenChatConversation)
    cloned.thread = stats.thread
    state.chosenChatConversation = cloned
  },
  updateExpenses(state, expenses) {
    let cloned = cloneDeep(state.currentListing)
    cloned.recent_expenses = expenses
    state.recentExpenses = expenses
    state.currentListing = cloned
  },
  updateRecentExpensesRules(state, expenses_rules) {
    let cloned = cloneDeep(state.currentListing)
    cloned.recent_expenses_rules = expenses_rules
    state.recentExpensesRules = expenses_rules
    state.currentListing = cloned
  },
  updateAccountingDataFetching(state, value) {
    state.accountingDataFetching = value
  },
  updateAccountingDataMonthly(state, accountingData) {
    let cloned = cloneDeep(state.currentListing)
    cloned.accounting_data = accountingData
    state.accountingDataMonthly = accountingData
    if (state.currentAccountingView == 'monthly') {
      state.accountingData = accountingData
    }
    state.currentListing = cloned
  },
  updateAccountingDataYearly(state, accountingData) {
    let cloned = cloneDeep(state.currentListing)
    cloned.accounting_data_yearly = accountingData
    state.accountingDataYearly = accountingData
    if (state.currentAccountingView != 'monthly') {
      state.accountingData = accountingData
    }
    state.currentListing = cloned
  },
  updateAccountingData(state, showMonthly) {
    if (showMonthly) {
      state.currentAccountingView = 'monthly'
    } else {
      state.currentAccountingView = 'yearly'
    }
    if (state.currentAccountingView == 'monthly') {
      state.accountingData = state.accountingDataMonthly
    } else {
      state.accountingData = state.accountingDataYearly
    }
  },
  addCommentToTask(state, data) {
    const commentToPush = {
      message: data.html,
      listing_task_id: data.id,
      created_at: new Date().getTime(),
      user_id: state.user.id,
      replies: [],
      seen: [state.user.id],
      parent_id: null,
    }
    if (data.parent_id) {
      state.currentlistingTask.listing_task_comments.map(comment => {
        if (comment.id === data.parent_id) {
          commentToPush.parent_id = data.parent_id
          comment.replies.push(commentToPush)
        }
        return comment
      })
    } else if (state.currentlistingTask) {
      state.currentlistingTask.listing_task_comments.unshift(commentToPush)
    }
  },
  addCommentToSetup(state, data) {
    const commentToPush = {
      message: data.html,
      listing_setup_id: data.id,
      created_at: new Date().getTime(),
      user_id: state.user.id,
      replies: [],
      seen: [state.user.id],
      parent_id: null,
    }
    const setupInd = state.listingSetups.findIndex(s => s.id === data.id)
    const setup = state.listingSetups[setupInd]
    if (data.parent_id) {
      setup.listing_setup_comments.map(comment => {
        if (comment.id === data.parent_id) {
          commentToPush.parent_id = data.parent_id
          comment.replies.push(commentToPush)
        }
        return comment
      })
    } else {
      setup.listing_setup_comments.unshift(commentToPush)
    }
    state.listingSetups[setupInd] = setup
  },
  setContractorPaymentsDetails(state, payload) {
    state.contractorPaymentsDetails = payload
  },
  setContractorPaymentsType(state, type) {
    state.contractorPaymentsType = type
  },
  setContractorPaymentsDateRange(state, payload) {
    state.contractorPaymentsDateRange[payload.range] = payload.value
  },
  updateLoadingConversation(state, value) {
    state.conversationLoading = value
  },
  setReservationsPagination(state, payload) {
    state.reservationsPagination = payload
  },
  setCurrentListingCalendar(state, { data }) {
    state.currentListing.days_rates = data.listing.days_rates
    state.currentListing.calender = data.calendar
  },
  toggleFetchingGuestExpInfo(state, value) {
    state.loadingGuestExpInfo = value
  },
  updateWhatsappTemplates(state, { data }) {
    state.whatsappTemplates = data
  },
  updateAiPolicy(state, data) {
    state.aiPolicy = data
  },
  updateServiceCallModalOpened(state, data) {
    state.serviceCallModalOpened = data.opened
    state.serviceCallModalPayload = data.payload
  },
  updateServiceCallAISuggestion(state, data) {
    state.serviceCallAISuggestion = data
  },
  updateCouponsRules(state, data) {
    state.couponsRules = data
  },
  updateCouponsRulesPagination(state, data) {
    state.couponsRulesPagination = data
  },
  updateCoupons(state, data) {
    state.coupons = data
  },
  updateCouponsPagination(state, data) {
    state.couponsPagination = data
  },
  updateAiAgentVersions(state, data) {
    state.aiAgentVersions = data
  },
  updateAiAgentVersionsPagination(state, data) {
    state.aiAgentVersionsPagination = data
  },
  updateAiVersionControls(state, data) {
    state.aiVersionControls = data
  },
  updateAiVersionControlsPagination(state, data) {
    state.aiVersionControlsPagination = data
  },
  updatelistingsNoScope(state, data) {
    state.listingsNoScope = data
  },
}
const actions = {
  refreshChatConversations: function (
    context,
    { from_price, to_price, ...filters }
  ) {
    if (from_price) {
      filters.from_price = from_price
      filters.to_price = to_price
    }
    filters.until_page = context.state.conversationsPagination
      ? Number(context.state.conversationsPagination.page)
      : 1
    axios
      .get('/api/conversations', { params: filters })
      .then(function (response) {
        context.commit(
          'updateChatConversations',
          response.data.payload.data.conversations
        )
        context.commit('updateChatStats', response.data.payload.data.chat_stats)
        if (context.state.chosenChatConversation) {
          var index = findIndex(response.data.payload.data.conversations, {
            id: context.state.chosenChatConversation.id,
          })
          if (index != -1) {
            context.commit(
              'updateChosenChatConversationThread',
              response.data.payload.data.conversations[index]
            )
          }
        }
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getChatConversations: async function (
    context,
    { from_price, to_price, ...filters }
  ) {
    context.commit('updateListingLoading', true)
    if (from_price) {
      filters.from_price = from_price
      filters.to_price = to_price
    }
    filters.page = context.state.conversationsPagination
      ? Number(context.state.conversationsPagination.page) + 1
      : 1
    filters.get_stats = true

    axios
      .get('/api/conversations', { params: filters })
      .then(function (response) {
        const { conversations, pagi_info, chat_stats, active_comm_users } =
          response.data.payload.data
        context.commit('updateListingLoading', false)
        context.commit('updateActiveCommUsers', active_comm_users)
        context.commit('updateConversationsPagination', pagi_info)
        context.commit('updateChatStats', chat_stats)
        if (filters.add) {
          const aggregatedConversations = unionBy(
            context.state.chatConversations,
            conversations || [],
            function (e) {
              return e.id
            }
          )
          context.commit('updateChatConversations', aggregatedConversations)
        } else {
          context.commit('updateChatConversations', conversations)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  },
  async getAllInfo({ commit }, filters) {
    if (!filters.stats_only && !filters.hideLoader) {
      commit('toggleFetchingGuestExpInfo', true)
    }
    const response = await axios.get('/api/conversations/all', {
      params: filters,
    })
    commit('toggleFetchingGuestExpInfo', false)

    commit('updateActiveCommUsers', response.data.stats.active_comm_users)
    return response
  },
  async getSalesInfo({ commit }, filters) {
    if (!filters.stats_only && !filters.hideLoader) {
      commit('toggleFetchingGuestExpInfo', true)
    }
    const response = await axios.get('/api/conversations/sales', {
      params: filters,
    })
    commit('toggleFetchingGuestExpInfo', false)

    commit('updateActiveCommUsers', response.data.stats.active_comm_users)
    return response
  },
  async getUsersInfo({ commit }, filters) {
    if (!filters.stats_only && !filters.hideLoader) {
      commit('toggleFetchingGuestExpInfo', true)
    }
    const response = await axios.get('/api/conversations/users', {
      params: filters,
    })
    commit('toggleFetchingGuestExpInfo', false)

    commit('updateActiveCommUsers', response.data.stats.active_comm_users)
    return response
  },
  async getAgentsInfo({ commit }, filters) {
    if (!filters.stats_only && !filters.hideLoader) {
      commit('toggleFetchingGuestExpInfo', true)
    }
    const response = await axios.get('/api/conversations/agents', {
      params: filters,
    })
    commit('toggleFetchingGuestExpInfo', false)

    commit('updateActiveCommUsers', response.data.stats.active_comm_users)
    return response
  },
  async getReviewsInfo({ commit }, filters) {
    commit('toggleFetchingGuestExpInfo', true)

    const response = await axios.get('/api/reviews/stats', {
      params: filters,
    })
    commit('toggleFetchingGuestExpInfo', false)
    return response
  },
  async getCsInfo({ commit }, filters) {
    if (!filters.stats_only && !filters.hideLoader) {
      commit('toggleFetchingGuestExpInfo', true)
    }

    const response = await axios.get('/api/conversations/cs', {
      params: filters,
    })
    commit('toggleFetchingGuestExpInfo', false)
    commit('updateActiveCommUsers', response.data.stats.active_comm_users)
    return response
  },
  getDailySchedule(context, params) {
    context.commit('updateListingLoading', true)
    axios
      .get(`/api/listing-tasks/daily-schedule?date=${params.date}`)
      .then(function (response) {
        context.commit('updateListingLoading', false)
        context.commit('updateDailySchedule', response.data.payload.data)
      })
      .catch(function (error) {
        context.commit('updateListingLoading', false)
        alert(error)
      })
  },
  getChatConversationTags: function (context) {
    axios
      .get('/api/conversations/tags/pre-made')
      .then(function (response) {
        context.commit(
          'updatePreMadeMessages',
          response.data.payload.data.pre_made_messages
        )
        context.commit(
          'updateConversationTags',
          response.data.payload.data.tags
        )
        context.commit(
          'updateConversationTagsObjects',
          response.data.payload.data.tags_objs
        )
      })
      .catch(function (error) {
        alert(error)
      })
  },
  async getConversationBG(context, id) {
    if (id === context.state.chosenChatConversation.guesty_id) {
      const { data } = await axios.get(`/api/conversations/${id}`)
      if (
        !context.state.chosenChatConversation ||
        data.id === context.state.chosenChatConversation.id
      ) {
        context.commit('updateChosenChatConversation', data)
        context.commit('updateSingleConversation', data)
      }
    }
  },
  async getChatData({ commit }, id) {
    commit('updateFetchingChatConversation', true)
    try {
      const { data: conversation } = await axios.get(`/api/conversations/${id}`)
      commit('updateFetchingChatConversation', false)

      return conversation
    } catch (e) {
      e => console.log(e)
    }
    commit('updateFetchingChatConversation', false)
  },

  async getChatConversation({ commit, dispatch }, id) {
    commit('updateLoadingConversation', true)
    const conversation = await dispatch('getChatData', id)
    try {
      commit('updateLoadingConversation', false)
      if (
        Array.isArray(conversation.related_reservations) &&
        conversation.related_reservations.length > 0
      ) {
        const chosenReservation = conversation.related_reservations.find(
          r => r.id === conversation.active_reservation.id
        )
        commit('updateChatReservation', chosenReservation)
      }

      commit('updateChosenChatConversation', conversation)
      commit('updateSingleConversation', conversation)
      return conversation
    } catch (err) {
      commit('updateLoadingConversation', false)
      alert(err)
    }
  },
  async getMultiCalendarChat({ commit, dispatch }, { id, reservationId }) {
    commit('updateLoadingConversation', true)
    const conversation = await dispatch('getChatData', id)
    const chosenReservation = conversation.related_reservations.find(
      r => r.id === reservationId
    )
    commit('updateChatReservation', chosenReservation)
    commit('updateChosenChatConversation', conversation)
    commit('updateSingleConversation', conversation)
  },
  async getChatMessages(context, id) {
    const { data } = await axios.get(`/api/conversations/${id}/messages`)
    if (
      !context.state.chosenChatConversation ||
      data.id === context.state.chosenChatConversation.id
    ) {
      context.commit('updateChosenChatConversation', data)
      context.commit('updateSingleConversation', data)
    }
  },
  createConverastionTag: function (context, newTag) {
    context.commit('updateListingLoading', true)
    axios
      .post(`/api/conversations/tags/new`, newTag)
      .then(function (response) {
        context.commit(
          'updateConversationTags',
          response.data.payload.data.tags
        )
        context.commit(
          'updateConversationTagsObjects',
          response.data.payload.data.tags_objs
        )
        context.commit('updateListingLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  deletePreMadeMessage: function (context, id) {
    context.commit('updateListingLoading', true)
    axios
      .delete(`/api/conversations/pre-made-messages/${id}`)
      .then(function (response) {
        context.commit(
          'updatePreMadeMessages',
          response.data.payload.data.pre_made_messages
        )
        context.commit('updateListingLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  async createPreMadeMessage(context, newMessage) {
    const { data } = await axios.post(
      `/api/conversations/pre-made-messages/new`,
      newMessage
    )
    context.commit('updatePreMadeMessages', data)
  },
  async conversationUpdate(context, filters) {
    context.commit('updateListingLoading', true)
    return await customAxios
      .patch(`/api/conversations/${filters.id}`, filters)
      .then(({ data }) => {
        context.commit('updateSingleConversation', data)
        context.commit('updateChosenChatConversation', data)
        context.commit('updateListingLoading', false)
      })
  },
  conversationUpdateBG: function (context, filters) {
    customAxios()
      .patch(`/api/conversations/${filters.id}`, filters)
      .then(({ data }) => {
        context.commit('updateSingleConversation', data)
        context.commit('updateChosenChatConversation', data)
      })
  },
  updateConversationUsageData: function (context, filters) {
    context.commit('updateListingLoading', true)
    axios
      .post(`/api/conversations/${filters.id}/update-usage-data`, filters)
      .then(function (response) {
        context.commit('updateSingleConversation', response.data.payload.data)
        context.commit(
          'updateChosenChatConversation',
          response.data.payload.data
        )
        context.commit('updateListingLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  sendChatMessage: function (context, filters) {
    context.commit('chatMessageOptimisticUpdate', {
      ...filters,
      rep_name: context.getters.username,
    })
    context.commit('updateSendMessageLoading', true)
    return apiAxios
      .post(`/api/conversations/${filters.id}/send_message`, filters)
      .then(({ data }) => {
        context.commit('updateSingleConversation', data)

        if (
          !context.state.chosenChatConversation ||
          context.state.chosenChatConversation.id === filters.id ||
          filters.id === 'new'
        ) {
          context.commit('updateChosenChatConversation', data)
        }
        context.commit('updateListingLoading', false)
        context.commit('updateSendMessageLoading', false)
      })
      .catch(error => {
        console.error(error)
        context.commit('updateSendMessageLoading', false)
      })
  },
  getTodayChecks: function (context) {
    context.commit('updateListingLoading', true)
    axios
      .get('/api/today-checks')
      .then(function (response) {
        context.commit('updateTodayChecks', response.data.payload.data)
        context.commit('updateListingLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getListingInfoItems: function (context, data) {
    const params = {}
    if (data.searchTerm) {
      params.search_term = data.searchTerm
    }
    axios
      .get(`/api/listing-info-items/${data.listingId || 0}`, { params })
      .then(function (response) {
        if (response.data.payload.data) {
          context.commit(
            'updateListingInfoItems',
            response.data.payload.data.info_items
          )
          context.commit(
            'updateListingInfoWarnings',
            response.data.payload.data.warnings
          )
          context.commit('updateListingLoading', false)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  },
  createListingInfoItem({ commit, dispatch }, payload) {
    commit('updateListingLoading', true)
    return axios
      .post('/api/listing-info-items', payload)
      .then(() => {
        dispatch('getListingInfoItems', {
          listingId: payload.listing_id,
        })
        commit('updateListingLoading', false)
      })
      .catch(error => {
        alert(error)
        commit('updateListingLoading', false)
      })
  },
  updateListingInfoItem({ commit, dispatch }, payload) {
    commit('updateListingLoading', true)
    return axios
      .patch(`/api/listing-info-items/${payload.id}`, payload)
      .then(() => {
        if (payload.listings_ids) {
          Toaster.show([{ type: 'success', text: 'Info copied successfully' }])
        }
        dispatch('getListingInfoItems', {
          listingId: payload.listing_id,
        })
      })
      .catch(error => {
        commit('updateListingLoading', false)
        alert(error)
      })
  },
  deleteListingInfoItem({ commit, dispatch }, payload) {
    commit('updateListingLoading', true)
    return axios
      .delete(`/api/listing-info-items/${payload.id}`)
      .then(() => {
        dispatch('getListingInfoItems', {
          listingId: payload.listing_id,
        })
      })
      .catch(error => {
        commit('updateListingLoading', false)
        alert(error)
      })
  },
  createListingSetup({ commit, dispatch }, payload) {
    commit('updateLoading', true)
    return axios
      .post('/api/setup-listing', payload)
      .then(() => {
        commit('updateLoading', false)
        dispatch('getAllListingsSetups', { status: 'All' })
      })
      .catch(error => {
        alert(error)
        commit('updateLoading', false)
      })
  },
  getCreationInfo({ commit }, data) {
    const params = {}
    params.listings_ids = data.listingsIds
    if (data.listingTaskCatalogItemId) {
      params.listing_task_catalog_item_id = data.listingTaskCatalogItemId
    }
    if (data.id) {
      params.id = data.id
    }
    return axios
      .get('/api/listing-tasks/create-suggestions', { params })
      .then(({ data }) => {
        commit('updateListingTaskLoading', false)
        commit('updateListingTaskCreationInfo', data)
      })
      .catch(error => {
        console.error(error)
        commit('updateListingTaskLoading', false)
      })
  },
  getCurrentUser: async function (context) {
    const response = await axios.get('/api/get-current-user')
    try {
      const user = response.data.payload.data
      if (user) {
        context.commit('updateUser', user)
        if (
          navigator.geolocation &&
          user &&
          user.email &&
          (user.role === 'contractor' ||
            user.role === 'property-manager' ||
            user.role === 'coordinator' ||
            user.role === 'field-pm')
        ) {
          navigator.geolocation.getCurrentPosition(function (position) {
            axios
              .post('/api/update-location', {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              })
              .then(() => {
                console.log('updated loc')
              })
              .catch(function (error) {
                if (errorStatus(error) === 403) {
                  router.push('/login')
                } else {
                  console.error(error)
                }
              })
          })
        }
      }
      return user
    } catch (error) {
      if (error.response.status === 401) {
        router.push('/login')
      }
    }
  },
  getListingTasksStats: function (context, data) {
    axios
      .get('/api/listing-tasks-stats?from=' + data.from + '&&to=' + data.to)
      .then(function (response) {
        context.commit('updateListingTasksStats', response.data.payload.data)
      })
      .catch(error => {
        if (errorStatus(error) !== 403) {
          alert(error)
        }
      })
  },
  getListingTaskPaymentsStats: function (context, data) {
    const params = {
      from: data.from,
      to: data.to,
      contractor_id: data.id,
      page: data.page,
      excel: data.excel,
      per_page: data.perPage,
      on_payrole: data.onPayrole,
      division: data.division,
      sort_by: data.sort_by,
    }
    return axios
      .get(`/api/listing-tasks-payments-stats`, {
        params,
        responseType: params.excel ? 'blob' : 'json',
        cancelToken: autoCancelToken('ListingTaskPaymentsStats'),
      })
      .then(({ data }) => {
        if (params.excel) {
          common_functions.methods.autoDownloadBlobData(
            data,
            `Contractor_Payments-${params.from}_${params.to}.xlsx`
          )
        } else {
          context.commit('updateListingTasksPaymentsStats', data.stats)
        }
        return data
      })
      .catch(error => {
        console.log(error)
      })
  },
  getListingTaskContractorPayments(_context, payload) {
    return axios
      .get(`/api/listing-tasks-contractor-payments`, {
        params: payload,
        responseType: 'blob',
        cancelToken: autoCancelToken('ListingTaskPaymentsStats'),
      })
      .then(({ data }) => {
        common_functions.methods.autoDownloadBlobData(
          data,
          `${payload.name}.xlsx`
        )
        return data
      })
      .catch(error => {
        console.log(error)
      })
  },
  getContractorPaymentsDetails(context, data) {
    context.commit('updateListingLoading', true)
    axios
      .get(`/api/contractor-payments/${data.id}/stats`, {
        params: data,
        cancelToken: autoCancelToken('GetContractorPaymentsDetails'),
      })
      .then(response => {
        context.commit('setContractorPaymentsDetails', response.data)
        context.commit('updateListingLoading', false)
      })
  },
  getNewInvestmentProperty(context, data) {
    context.commit('updateLoading', true)
    axios
      .get(
        '/api/investment-analysis?address=' +
          data.address +
          '&&beds=' +
          data.beds +
          '&&baths=' +
          data.baths
      )
      .then(function (response) {
        context.commit(
          'updateCurrentInvestmentListing',
          response.data.payload.data
        )
        context.commit('updateLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getManagementStats: function (context, date) {
    if (context.state.user && context.state.user.role === 'admin') {
      if (!date) {
        date = new Date().toISOString().substr(0, 10)
      }
      axios
        .get('/api/management-stats?date=' + date)
        .then(function (response) {
          context.commit('updateManagmentStats', response.data.payload.data)
        })
        .catch(function (error) {
          alert(error)
        })
    }
  },
  getParkings: function (context) {
    context.commit('updateListingLoading', true)
    axios
      .get('/api/parkings')
      .then(function (response) {
        context.commit('updateParkings', response.data.payload.data)
        context.commit('updateListingLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getCompanyActivity: function (context) {
    context.commit('updateLoading', true)
    axios
      .get('/api/company-activity')
      .then(function (response) {
        context.commit('updateCompanyActivity', response.data.payload.data)
        context.commit('updateLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getTrackedListingTask: function (context, id) {
    context.commit('updateLoading', true)
    axios
      .get(`/api/tracked-lt/${id}`)
      .then(function (response) {
        context.commit('updateTrackedListingTask', response.data.payload.data)
        context.commit('updateLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  updateBankTransaction: function (context, transaction) {
    context.commit('updateListingLoading', true)
    axios
      .post(
        `/api/bank-accounts/bank_transaction/${transaction.id}/update`,
        transaction
      )
      .then(function (response) {
        context.commit('updateChosenTransaction', response.data.payload.data)
        context.commit('updateListingLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  async dismissItem(context, itemDetails) {
    context.commit('updateListingLoading', true)
    const { data } = await axios.post(`/api/bank-accounts/dismiss`, itemDetails)
    if (data.listing_id) {
      context.dispatch('changeEditListingBG', data.listing_id, true)
    }
    context.commit('updateListingLoading', false)
    return data
  },
  bankTransactionDueToDo: function (context, { id, value }) {
    context.commit('updateListingLoading', true)
    return axios
      .post(`/api/bank-accounts/due-to-do/${id}`, { due_to_do: value })
      .catch(alert)
      .finally(() => {
        context.commit('updateListingLoading', false)
      })
  },
  getPricingMonthlyStats(context, { listingId, date }) {
    const { currentPricing, currentListing } = context.state
    const monthYear = date.split(' ')
    const id = listingId || (currentListing && currentListing.id)
    const pricing = currentPricing || (currentListing && currentListing.pricing)

    if (id) {
      const params = new URLSearchParams()
      context.commit('updateListingLoading', true)
      let url = `/api/listing/${id}/pricing/monthly_stats`
      params.append('year', monthYear[1])
      params.append('month', monthYear[0])
      if (get('pricing.base_price', context.state.currentListing)) {
        params.append('base_price', pricing.base_price)
        params.append('max_price', pricing.max_price)
        params.append('min_price', pricing.min_price)
        params.append('factors', JSON.stringify(pricing.factors))
      }
      axios
        .get(url, { params })
        .then(function (response) {
          context.commit(
            'updatePricingMonthlyStats',
            response.data.payload.data
          )
          context.commit('updateListingLoading', false)
        })
        .catch(function (error) {
          context.commit('updateListingLoading', false)
          console.error('Pricing not found for this property', error)
        })
    }
  },
  getPricingYearlyStats(context, current_date) {
    const monthYear = current_date.split(' ')
    const pricing = context.state.currentPricing
    context.commit('updateListingLoading', true)
    axios
      .get(
        '/api/pricing/' +
          context.state.currentPricing.id +
          '/yearly_stats?year=' +
          monthYear[1] +
          `&&base_price=${pricing.base_price}&&max_price=${
            pricing.max_price
          }&&min_price=${pricing.min_price}&&factors=${JSON.stringify(
            pricing.factors
          )}`
      )
      .then(function (response) {
        context.commit('updatePricingYearlyStats', response.data.payload.data)
        context.commit('updateListingLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  updateListingSetup: function (context, listingSetup) {
    context.commit('updateListingLoading', true)
    axios
      .patch(`/api/listings-setups/${listingSetup.id}`, listingSetup)
      .then(response => {
        context.commit('updateSinglelistingSetups', response.data.payload.data)
        context.commit('updateListingLoading', false)
      })
      .catch(() => {
        alert('something bad happend')
      })
  },
  getListingsSetups: function (context, filters) {
    context.commit('updateLoading', true)
    axios
      .get('/api/listings-setups', { params: filters })
      .then(function (response) {
        context.commit('updateLoading', false)
        context.commit('updatelistingSetups', response.data.payload.data)
      })
      .catch(function (error) {
        context.commit('updateLoading', false)
        alert(error)
      })
  },
  getAllPricing: function (context, filters) {
    context.commit('updateLoading', true)
    let url =
      '/api/pricing?page=' + filters.page + '&&activated=' + filters.activated
    if (filters.search_term) {
      url = url + '&&search_term=' + filters.search_term
    }
    if (filters.only_multi) {
      url = url + '&&only_multi=' + filters.only_multi
    }
    if (filters.only_listed) {
      url = url + '&&only_listed=' + filters.only_listed
    }
    axios
      .get(url)
      .then(function (response) {
        context.commit('updatePricings', response.data.payload.data)
        context.commit(
          'updatePricingPagniation',
          response.data.payload.data.pagi_info
        )

        context.commit('updateLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getListingPricing: function (context, id) {
    context.commit('updateLoading', true)
    return axios
      .get('/api/pricing/' + id)
      .then(function (response) {
        context.commit('updateLoading', false)
        context.commit('updateCurrentPricing', response.data.payload.data)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getListingPricingByListing: function (context, id) {
    context.commit('updateLoading', true)
    axios
      .get('/api/pricing/' + id + '?listing_id=true')
      .then(function (response) {
        context.commit('updateLoading', false)
        context.commit('updateCurrentPricing', response.data.payload.data)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getExpensesByFilter: async function (context, { excel, ...filters }) {
    context.commit('updateListingLoading', true)
    filters.from_price = filters.fromPrice || 0
    filters.to_price = filters.toPrice || 1000000
    filters.listings_ids = filters.listingsIds
    filters.reservation_id = filters.reservationId
    filters.store_search = filters.storeSearch
    let date = new Date()
    filters.from =
      filters.from ||
      new Date(date.getFullYear(), date.getMonth() - 1, 1)
        .toISOString()
        .substr(0, 10)
    filters.to =
      filters.to ||
      new Date(date.getFullYear(), date.getMonth(), 1)
        .toISOString()
        .substr(0, 10)
    context.commit('updateLastExpensesFilter', filters)
    context.commit('updateFetchingExpenses', true)
    if (excel) {
      axios
        .get('/api/filtered-expenses', {
          responseType: 'blob',
          params: { ...filters, excel: true },
          cancelToken: autoCancelToken('FilterExpenses'),
        })
        .then(({ data }) => {
          context.commit('updateFetchingExpenses', false)

          common_functions.methods.autoDownloadBlobData(
            new Blob([data]),
            `${filters.from}-${filters.to}-expenses.xlsx`
          )
        })
      return
    }
    const { data } = await axios
      .get('/api/filtered-expenses', { params: filters })
      .catch(function (error) {
        alert(error)
      })

    context.commit('updateListingLoading', false)
    context.commit('updateExpenses', data.expenses)
    await context.commit('updateRecentExpensesRules', data.expenses_rules)
    context.commit('updateFetchingExpenses', false)

    return data
  },
  getAutoMessageRules: function (context, { id, ...params }) {
    context.commit('updateListingLoading', true)
    axios
      .get(`/api/auto-message-rules/${id || ''}`, { params })
      .then(({ data }) => {
        context.commit('updateListingLoading', false)
        context.commit('updateAutoMessageRules', data)
      })
      .catch(error => {
        if (errorStatus(error) !== 403) {
          console.log(error)
        }
      })
  },
  getWhatsappTemplates: function (context) {
    return axios
      .get(`/api/auto-message-rules/whatsapp-templates`)
      .then(data => {
        context.commit('updateWhatsappTemplates', data)
        return data
      })
  },
  getBookingEngineSettings: function (context, { id, ...params }) {
    context.commit('updateListingLoading', true)
    axios
      .get(`/api/booking-engine-settings/${id || ''}`, { params })
      .then(({ data }) => {
        context.commit('updateListingLoading', false)
        context.commit('updateBookingEngineSettings', data)
      })
      .catch(error => {
        if (errorStatus(error) !== 403) {
          console.log(error)
        }
      })
  },
  getAutoListingTaskRules: function (context, filters) {
    const params = new URLSearchParams()
    let url = `/api/auto-listing-tasks/` + filters.id
    if (filters.contractor_id) {
      params.append('contractor_id', filters.contractor_id)
    }
    if (filters.listings_ids) {
      params.append('listings_ids', filters.listings_ids)
    }
    if (filters.region_id) {
      params.append('region_id', filters.region_id)
    }
    params.append('company_only', filters.company_only)
    params.append('manual_only', filters.manual_only)
    params.append('active_only', filters.active_only)
    params.append('page', filters.page || 1)
    if (filters.only_master) {
      params.append('only_master', filters.only_master)
    }
    if (filters.task_type) {
      params.append('task_type', filters.task_type)
    }
    if (filters.sub_topic) {
      params.append('sub_topic', filters.sub_topic)
    }
    if (filters.search_term) {
      params.append('search_term', filters.search_term)
    }
    context.commit('updateListingLoading', true)
    axios
      .get(url, { params })
      .then(function (response) {
        context.commit('updateListingLoading', false)
        context.commit(
          'updateAutoListingTaskRules',
          response.data.payload.data.rules
        )
        context.commit(
          'updateAutoRulesPagination',
          response.data.payload.data.pagi_info
        )
      })
      .catch(error => {
        if (errorStatus(error) !== 403) {
          alert(error)
        }
      })
  },
  getOptionalListings: function (context, params) {
    context.commit('updateListingLoading', true)
    axios
      .get('/api/optional-listings?', { params })
      .then(function (response) {
        context.commit('updateListingLoading', false)
        context.commit('updateOptionalListings', response.data.payload.data)
      })
      .catch(error => {
        if (errorStatus(error) !== 403) {
          console.error(error)
        }
      })
  },
  getCommunicationWarnings: function (context) {
    context.commit('updateListingLoading', true)
    axios
      .get(`/api/communication-warnings`)
      .then(function (response) {
        context.commit(
          'updateCommunicationWarnings',
          response.data.payload.data
        )
        context.commit('updateListingLoading', false)
      })
      .catch(error => {
        if (errorStatus(error) !== 403) {
          alert(error)
        }
      })
  },
  getRulesWarnings: function (context) {
    axios
      .get(`/api/listing-tasks/warnings/rules`)
      .then(function (response) {
        context.commit('updateRulesWarnings', response.data.payload.data)
      })
      .catch(error => {
        if (errorStatus(error) !== 403) {
          alert(error)
        }
      })
  },
  getRequestsByFilter: function (context, filters) {
    if (!filters) {
      filters = context.state.lastRequestFilter
    } else {
      context.commit('updatelastRequestFilter', filters)
    }
    if (!filters.request_id) {
      if (!filters.priority) {
        filters.priority = 'All'
      }
      if (!filters.page) {
        filters.page = 1
      }
      if (!filters.per_page) {
        filters.per_page = 20
      }
      if (!filters.request_type) {
        filters.request_type = 'Repair'
      }
      if (isEmpty(filters.status)) {
        filters.status = ['New', 'In Progress']
      }
      if (isEmpty(filters.type)) {
        filters.type = ['Add', 'Take', 'Rent', 'Return']
      }
      if (!filters.only_open_today) {
        filters.only_open_today = false
      }
      if (!filters.from) {
        filters.from = new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          1
        )
          .toISOString()
          .substr(0, 10)
      }
      if (!filters.to) {
        filters.to = new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 2,
          1
        )
          .toISOString()
          .substr(0, 10)
      }
      filters.to_price = filters.toPrice || 100000
    }
    context.commit('updateListingLoading', true)
    return axios
      .get('/api/filtered-requests', { params: filters })
      .then(function ({ data }) {
        context.commit('updateListingLoading', false)
        context.commit('updateListingFieldManage', data.requests)
        context.commit('updateRequestsPagination', data.pagi_info)
        context.commit('storages/updateStorages', data.storages_options)
        return data
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getInventoryItems: function (context, filters) {
    // context.commit('updateListingLoading', true)
    const params = {}
    if (filters.catagory) {
      params.category = filters.catagory
    }
    if (filters.storage) {
      params.storage_nickname = filters.storage
    }
    if (filters.showZeroAmounts) {
      params.show_zero = true
    }
    params.page = filters.page || 1
    if (filters.search) {
      params.search = filters.search
    }
    return axios
      .get('/api/inventory-items', { params })
      .then(response => {
        // context.commit('updateListingLoading', false)
        context.commit(
          'updateInventoryItems',
          response.data.payload.data.inventory_items
        )
        context.commit(
          'storages/updateStorages',
          response.data.payload.data.storages_options
        )
        context.commit(
          'updateCatagoriesOptions',
          response.data.payload.data.catagories_options
        )
        context.commit(
          'updateInventoryPagination',
          response.data.payload.data.pagination
        )
      })
      .catch(error => {
        console.log(error)
      })
  },
  UpdateInventoryItem: function (context, item) {
    context.commit('updateListingLoading', true)
    axios
      .patch(`/api/inventory-items/${item.id}`, item)
      .then(() => {
        context.commit('updateListingLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  duplicateShift: function (context, { id, ...params }) {
    context.commit('updateListingLoading', true)
    axios
      .post(`/api/company-shifts/special-actions/${id}`, params)
      .catch(function (error) {
        alert(error)
      })
  },
  getBankTransactionsByFilter: function (context, filters) {
    context.commit('updateListingLoading', true)
    const params = {
      page: filters.page,
      per_page: filters.perPage,
      search: filters.search,
      listing_id: filters.listingId,
      user_id: filters.userId,
      chosen_contact: filters.chosenContact,
      chosen_credit: filters.chosenCredit,
      from: filters.from,
      to: filters.to,
      transaction_type: filters.transactionType,
      institution_name: filters.institutionName,
      from_price: filters.fromPrice,
      attached_to: filters.attachedTo,
      to_price: filters.toPrice,
      status: filters.status,
      only_due_to_do: filters.onlyDueToDo,
    }
    return axios
      .get(`/api/filtered-transactions`, { params })
      .then(function (response) {
        context.commit('updateListingLoading', false)
        context.commit(
          'updateTransactions',
          response.data.payload.data.transactions
        )
        context.commit(
          'updateTransactionsSummary',
          response.data.payload.data.summary
        )
        return response.data.payload.data
      })
      .catch(alert)
  },
  getSuggestionsTasks: function (context, filters) {
    axios
      .get(`/api/listing-tasks/get-suggestions?date=${filters.date}`)
      .then(function (response) {
        context.commit(
          'updateTaskCalendarSuggestions',
          response.data.payload.data
        )
      })
      .catch(function (error) {
        alert(error)
      })
  },
  refreshBankTransaction: function (context) {
    axios
      .get(`/api/bank-transactions/${context.state.chosenTransaction.id}`)
      .then(function (response) {
        context.commit('updateChosenTransaction', response.data.payload.data)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getInitialStats({ state, commit }, { id, ...params }) {
    commit('updateAccountingDataFetching', true)
    return axios
      .get(`/api/listings/${id || state.currentListing.id}/accounting_data`, {
        params,
        cancelToken: autoCancelToken(
          params.month ? 'MonthlyAccountingData' : 'YearlyAccountingData'
        ),
      })
      .then(({ data }) => {
        if (params.month) {
          commit('updateAccountingDataMonthly', data)
        } else {
          commit('updateAccountingDataYearly', data)
        }
        commit('updateAccountingDataFetching', false)

        return data
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          commit('updateAccountingDataFetching', true)
        } else {
          commit('updateAccountingDataFetching', false)
        }
      })
  },
  updateStats(context, { id, month, year, runYearly = false }) {
    context.commit('updateListingLoading', true)
    axios
      .get(`/api/listings/${id}/monthly_stats?year=${year}&month=${month}`)
      .then(function (response) {
        context.commit('updateMonthlyStats', response.data.payload.data)
        context.commit('updateListingLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
    if (runYearly) {
      axios
        .get(`/api/listings/${id}/yearly_stats?year=${year}`)
        .then(function (response) {
          context.commit('updateYealryStats', response.data.payload.data)
        })
        .catch(function (error) {
          alert(error)
        })
    }
  },
  async removeMatch(context, removedItem) {
    context.commit('updateListingLoading', true)
    let removedType = ''
    if (removedItem.check_in) {
      removedType = 'Reservation'
    } else if (removedItem.short_reason) {
      removedType = 'Expense'
    } else if (removedItem.income_type) {
      removedType = 'ListingIncome'
    } else if (removedItem.type == 'LedgerTransaction') {
      removedType = 'LedgerTransaction'
    } else if (removedItem.category) {
      removedType = 'CompanyIncome'
    } else {
      removedType = 'Payment'
    }
    let removedId = removedItem.id
    axios
      .post(
        `/api/bank-account/remove-match?transaction_id=${context.state.chosenTransaction.id}&&matched_id=${removedId}&&matched_type=${removedType}`
      )
      .then(function (response) {
        context.commit('updateChosenTransaction', response.data.payload.data)
        context.commit('updateListingLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  async addMatch(context, addedItem) {
    context.commit('updateListingLoading', true)
    let addedType = ''
    if (addedItem.check_in) {
      addedType = 'Reservation'
    } else if (addedItem.short_reason) {
      addedType = 'Expense'
    } else if (addedItem.income_type) {
      addedType = 'ListingIncome'
    } else if (addedItem.type == 'LedgerTransaction') {
      addedType = 'LedgerTransaction'
    } else if (addedItem.category) {
      addedType = 'CompanyIncome'
    } else {
      addedType = 'Payment'
    }
    let addedId = addedItem.id
    axios
      .post(
        `/api/bank-account/add-match?transaction_id=${context.state.chosenTransaction.id}&&matched_id=${addedId}&&matched_type=${addedType}`,
        { item: addedItem }
      )
      .then(function (response) {
        context.commit('updateChosenTransaction', response.data.payload.data)
        context.commit('updateListingLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  createNewShift: function (context, shift) {
    axios
      .post('/api/company-shifts/', shift)
      .then(() => ({}))
      .catch(function (error) {
        alert(error)
      })
  },
  deleteCompanyShift: function (context, shift) {
    axios
      .delete(`/api/company-shifts/${shift.id}`)
      .then(() => ({}))
      .catch(function (error) {
        alert(error)
      })
  },
  updateCompanyShift: function (context, shift) {
    axios
      .patch(`/api/company-shifts/${shift.id}`, shift)
      .then(() => ({}))
      .catch(function (error) {
        alert(error)
      })
  },
  getCompanyShifts: function (context, filters = null) {
    const params = {}
    if (filters) {
      params.to = filters.to
      params.from = filters.from
    }
    axios
      .get('/api/company-shifts', { params })
      .then(function (response) {
        context.commit('updateCompanyShifts', response.data.payload.data)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getCompanyTasks: function (context, filters = null) {
    if (filters.bg) {
      context.commit('updateListingLoading', true)
    } else {
      context.commit('updateLoading', true)
    }

    const params = {}
    if (filters) {
      params.to = filters.to
      params.from = filters.from
      if (filters.addID) {
        params.add_id = filters.addID
      }
      params.assigned_user_id = filters.assigned_user_id
    }
    axios
      .get('/api/company-tasks', { params })
      .then(function (response) {
        context.commit('updateCurrentCompanyTasks', response.data.payload.data)
        if (filters.bg) {
          context.commit('updateListingLoading', false)
        } else {
          context.commit('updateLoading', false)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getListingStaticTasks: function (context, filters) {
    context.commit('updateListingLoading', true)
    axios
      .get('/api/static-listing-lists', { params: filters })
      .then(function (response) {
        context.commit('updateListingStaticTasks', response.data.payload.data)
        context.commit('updateListingLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getListingSetup: function (context, id) {
    context.commit('updateLoading', true)
    axios
      .get(`/api/listings-setups/${id}`)
      .then(function (response) {
        context.commit(
          'updateCurrentListingSetup',
          response.data.payload.data.listing_setup
        )
        context.commit(
          'updateCurrentListingSetupStats',
          response.data.payload.data.stats
        )

        context.commit('changeListing', response.data.payload.data.listing)
        context.commit('updateLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getListingSetupBg: function (context, id) {
    context.commit('updateListingLoading', true)

    axios
      .get(`/api/listings-setups/${id}`)
      .then(function (response) {
        context.commit(
          'updateCurrentListingSetup',
          response.data.payload.data.listing_setup
        )
        context.commit(
          'updateCurrentListingSetupTasks',
          response.data.payload.data.listing_setup_tasks
        )
        context.commit(
          'updateCurrentListingSetupListNames',
          response.data.payload.data.setup_lists_names
        )
        context.commit('updateListingLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getOptionalMatch: function ({ state, commit }, data) {
    commit('updateListingLoading', true)
    axios
      .get(`/api/bank-account/options`, {
        params: {
          transaction_id: state.chosenTransaction.id,
          listing_id: data.id,
          light_rules: data.light_rules,
          obj_type: data.obj_type,
          confirmation_code: data.confirmation_code,
          search: data.search,
        },
      })
      .then(function (response) {
        commit('updateTransactionOptions', response.data.payload.data)
        commit('updateListingLoading', false)
      })
      .catch(alert)
  },
  getManagedReservations(context, filters) {
    context.commit('updateLoadingConversation', true)
    context.commit('updateLastReservationsFilter', filters)
    if (filters.excel) {
      axios
        .get('/api/reservations/manage', {
          params: filters,
          responseType: 'blob',
        })
        .then(res => {
          context.commit('updateLoadingConversation', false)
          common_functions.methods.autoDownloadBlobData(
            res.data,
            `Reservations_${filters.from}_${filters.to}.xlsx`
          )
        })
    } else {
      axios
        .get('/api/reservations/manage', { params: filters })
        .then(({ data }) => {
          const {
            pagination,
            totals,
            reservations,
            stats,
            show_verification_status = false,
          } = data
          context.commit('setReservationsPagination', pagination)
          context.commit('updateManagedReservations', {
            totals,
            reservations,
            stats,
            show_verification_status,
          })
          context.commit('updateLoadingConversation', false)
        })
        .catch(console.error)
    }
  },
  updateWarning: function (context, warning) {
    context.commit('updateListingLoading', true)
    axios
      .post('/api/communication-warnings/' + warning.id, warning)
      .then(() => {
        context.dispatch('getCommunicationWarnings')
        context.commit('updateListingLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  changeListing: function (context, { listingId, updateLoading = true }) {
    if (updateLoading) context.commit('updateLoading', true)
    return axios
      .get(`/api/listings/${listingId}`)
      .then(function (response) {
        context.commit('changeListing', response.data.payload.data)
        context.commit('updateLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  async getPreCheckIn(context, confirmationCode) {
    const { data } = await axios
      .get(`/api/check-in/${confirmationCode}`)
      .catch(console.log)
    return data
  },
  async updatePreCheckIn(_store, { id, ...payload }) {
    const { data } = await axios
      .post(`/api/check-in/${id}`, payload)
      .catch(error => console.log(error))
    return data
  },
  async pushToXero(_store, payload) {
    const { data } = await axios
      .post(`/api/xero-api/create-invoice`, payload)
      .then(function () {
        Toaster.show([
          { type: 'success', text: 'Item sent to Xero successfully' },
        ])
      })
      .catch(error => console.log(error))
  },
  changeEditListingBG: function (context, listingID) {
    axios
      .get(`/api/listings/${listingID}/edit`)
      .then(function (response) {
        context.commit('changeEditListing', response.data.payload.data, true)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  changeEditListing: function (context, listingID) {
    context.commit('updateLoading', true)
    axios
      .get(`/api/listings/${listingID}/edit`)
      .then(function (response) {
        context.commit('updateLoading', false)
        context.commit('changeEditListing', response.data.payload.data)
        jQuery('html,body').animate({ scrollTop: 0 }, 0)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  manualMarkReservation: function (context, info) {
    context.commit('updateListingLoading', true)
    axios
      .post(`/api/reservations/${info.id}/marketing?relevant=${info.relevant}`)
      .then(() => {
        context.commit('updateListingLoading', false)
        context.dispatch(
          'getManagedReservations',
          context.state.lastReservationsFilter
        )
      })
      .catch(function (error) {
        context.commit('updateListingLoading', false)
        alert(error)
      })
  },
  markAsPaid: function (context, reservationID) {
    context.commit('updateListingLoading', true)
    return axios
      .post(`/api/reservations/${reservationID}/paid`)
      .then(res => {
        context.commit('updateListingLoading', false)
        return res
      })
      .catch(function (error) {
        context.commit('updateListingLoading', false)
        alert(error)
      })
  },
  markUnPaid: function (context, reservationID) {
    context.commit('updateListingLoading', true)
    axios
      .post(`/api/reservations/${reservationID}/unpaid`)
      .then(() => {
        context.commit('updateListingLoading', false)
        context.dispatch(
          'getManagedReservations',
          context.state.lastReservationsFilter
        )
      })
      .catch(function (error) {
        context.commit('updateListingLoading', false)
        alert(error)
      })
  },
  preApproveReservation: function (context, reservationId) {
    context.commit('updateListingLoading', true)
    axios
      .post(`/api/reservations/${reservationId}/pre-approve`)
      .then(() => {
        context.commit('updateListingLoading', false)
      })
      .catch(function (error) {
        context.commit('updateListingLoading', false)
        alert(error)
      })
  },
  updateListingServices: function (context, { id, services }) {
    axios.post(`/api/listings/services/${id}/update`, { services })
  },
  updateListingInvestmentsInfo: function (context, investmentInfo) {
    context.commit('updateListingLoading', true)
    axios
      .post(
        '/api/listings/investment/' +
          context.state.currentListing.id +
          '/update',
        investmentInfo
      )
      .then(() => {
        context.commit('updateListingLoading', false)
      })
      .catch(function (error) {
        context.commit('updateLoading', false)
        alert(error)
      })
  },

  getRequestTasks(context, { filters }) {
    context.commit('updateListingTaskLoading', true)
    return axios
      .get(`/api/request-tasks`, {
        params: pickBy(Boolean, filters),
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: 'brackets' })
        },
      })
      .then(function ({ data }) {
        context.commit('updateListingTaskLoading', false)
        const { tasks, pagi_info } = data
        context.commit('updateListingTasks', tasks)
        context.commit('updateListingTasksPagination', pagi_info)
        return data
      })
  },
  getListingTasks(context, extraInfo) {
    let router = extraInfo['router']
    if (!extraInfo.filters) {
      extraInfo.filters = {
        from: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
          .toISOString()
          .substr(0, 10),
        to: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
          .toISOString()
          .substr(0, 10),
        status: [],
        type: 'All',
      }
    }
    context.commit('updateListingLoading', true)
    return axios
      .get(`/api/listing-tasks`, {
        params: pickBy(Boolean, extraInfo.filters),
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: 'brackets' })
        },
      })
      .then(function ({ data }) {
        context.commit('updateListingLoading', false)
        const {
          listings_resources,
          contractors_resources,
          project_stats,
          company_users,
          tasks,
          map_center,
          pagi_info,
          total_jobs,
          pending_accept,
        } = data
        context.commit('updateMapCenter', map_center)

        context.commit('updateTasksCalendarResources', {
          listings_resources,
          contractors_resources,
        })

        context.commit('updateProjectsStats', project_stats)
        if (pagi_info) {
          context.commit('updateListingTasksPagination', pagi_info)
        }

        if (total_jobs) {
          context.commit('updateListingTaskTotalCount', total_jobs)
        }

        if (pending_accept) {
          context.commit('updatePendingAccept', pending_accept)
        }

        if (extraInfo.filters.saveToCal) {
          context.commit('updateCompanyUsers', company_users)
        }
        context.commit('updateListingTasks', tasks)
        if (tasks.length === MAX_SC_TASKS_RESULT) {
          Toaster.show([
            {
              type: 'error',
              timeout: 8000,
              text: `Service calls response is limited to ${MAX_SC_TASKS_RESULT} results in order to get
               a better data integrity please specify your filters to be more precisely`,
            },
          ])
        }
        return data
      })
      .catch(function (error) {
        context.commit('updateListingLoading', false)
        if (errorStatus(error) === 403) {
          if (router) {
            router.push('/login')
          }
        } else {
          alert(error)
        }
      })
  },
  getPendingListingTasks(context, extraInfo) {
    let router = extraInfo['router']
    if (!extraInfo.filters) {
      extraInfo.filters = {
        from: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
          .toISOString()
          .substr(0, 10),
        to: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
          .toISOString()
          .substr(0, 10),
        status: [],
        type: 'All',
      }
    }
    context.commit('updateListingLoading', true)
    return axios
      .get(`/api/listing-tasks`, {
        params: pickBy(Boolean, extraInfo.filters),
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: 'brackets' })
        },
      })
      .then(function ({ data }) {
        context.commit('updateListingLoading', false)
        const {
          listings_resources,
          contractors_resources,
          project_stats,
          company_users,
          tasks,
          map_center,
          pagi_info,
          total_jobs,
        } = data
        context.commit('updateMapCenter', map_center)

        context.commit('updateTasksCalendarResources', {
          listings_resources,
          contractors_resources,
        })

        context.commit('updateProjectsStats', project_stats)
        if (pagi_info) {
          context.commit('updateListingTasksPagination', pagi_info)
        }

        if (total_jobs) {
          context.commit('updateListingTaskTotalCount', total_jobs)
        }

        if (extraInfo.filters.saveToCal) {
          context.commit('updateCompanyUsers', company_users)
        }
        context.commit('updatePendingListingTasks', tasks)
        if (tasks.length === MAX_SC_TASKS_RESULT) {
          Toaster.show([
            {
              type: 'error',
              timeout: 8000,
              text: `Service calls response is limited to ${MAX_SC_TASKS_RESULT} results in order to get
               a better data integrity please specify your filters to be more precisely`,
            },
          ])
        }
        return data
      })
      .catch(function (error) {
        context.commit('updatePendingListingLoading', false)
        if (errorStatus(error) === 403) {
          if (router) {
            router.push('/login')
          }
        } else {
          alert(error)
        }
      })
  },
  updateGenericListsProgress: function (context, list) {
    context.commit('updateListingLoading', true)
    axios
      .patch(`/api/update-generic-list/${list.id}`, list)
      .then(() => {
        context.commit('updateListingLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getListingTaskReport(context, { id }) {
    context.commit('updateLoading', true)
    return axios
      .get(`/api/listing-tasks/${id}/report`)
      .then(({ data }) => {
        context.commit('updateLoading', false)
        context.commit('updateCurrentListingTask', data)
        return data
      })
      .catch(error => {
        context.commit('updateLoading', false)
        console.error(error)
      })
  },
  getListingTask(context, { disableLoading, ...payload }) {
    if (
      navigator.geolocation &&
      window.matchMedia('(display-mode: standalone)').matches
    ) {
      navigator.geolocation.getCurrentPosition(function (position) {
        axios
          .post('/api/update-location', {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
          .then(() => {
            console.log('updated loc')
          })
          .catch(() => ({}))
      })
    }
    if (!disableLoading) context.commit('updateLoading', true)
    return axios
      .get(`/api/listing-tasks/${payload.id}`)
      .then(({ data }) => {
        if (!disableLoading) context.commit('updateLoading', false)
        context.commit('updateCurrentListingTask', data.listing_task)
      })
      .catch(error => {
        context.commit('updateLoading', false)
        if (errorStatus(error) === 403) {
          payload.router.push('/login')
        } else {
          console.error(error)
        }
      })
  },
  getListingTaskBg: function (context, id) {
    if (id) {
      context.commit('updateListingTaskLoading', true)
      return axios
        .get(`/api/listing-tasks/${id}`)
        .then(({ data }) => {
          context.commit('updateCurrentListingTask', data.listing_task)
          context.commit('taskCalendar/toggleCalendarRefresh')
          context.commit('listingReport/updatedReportTask', data.listing_task)
          context.commit('updateListingTask', data.listing_task)
          context.commit('updateListingTaskLoading', false)
          return data.listing_task
        })
        .catch(error => {
          context.commit('updateListingTaskLoading', false)
          console.error(error)
        })
    }
  },
  getPricingStats: function (context, data) {
    let params = `date=${data.from}`
    if (data.tags) {
      params += '&&tags=' + data.tags
    }
    axios
      .get('/api/pricing-stats?' + params)
      .then(function (response) {
        context.commit(
          'updatePricingReservationsStats',
          response.data.payload.data.reservations_stats
        )
        context.commit(
          'updatePricingMonthlyStats',
          response.data.payload.data.monthly_stats
        )
        context.commit(
          'updatePricingYearlyStats',
          response.data.payload.data.yearly_stats
        )
      })
      .catch(function (error) {
        alert(error)
      })
  },
  savePricing: function (
    context,
    { pricing, title, pricing_type, static_pricing }
  ) {
    context.commit('updateLoading', true)
    const updateHash = {
      base_price: pricing.base_price,
      min_price_weekend: pricing.min_price_weekend,
      min_price_weekday: pricing.min_price_weekday,
      max_price: pricing.max_price,
      dynamic_nights_1_night: pricing.dynamic_nights_1_night,
      dynamic_nights_2_night: pricing.dynamic_nights_2_night,
      scramble_prices: pricing.scramble_prices,
      min_nights_weekend: pricing.min_nights_weekend,
      min_nights_weekday: pricing.min_nights_weekday,
      factors: pricing.factors,
      activated: pricing.activated,
      enable_emergency_mode: pricing.enable_emergency_mode,
      enable_group_pricing: pricing.enable_group_pricing,
      enable_multi_unit_pricing: pricing.enable_multi_unit_pricing,
      enable_trapped_between_weekends: pricing.enable_trapped_between_weekends,
      pricing_strategy_id: pricing.pricing_strategy_id,
      pricing_type,
      static_pricing,
    }
    axios
      .post(`/api/pricing/${pricing.id}`, updateHash)
      .then(function (response) {
        context.dispatch('getPricingStrategies')
        context.commit('updateCurrentPricing', response.data.payload.data)
        if (context.state.calendar) {
          context.dispatch('getPricingMonthlyStats', {
            date: title,
          })
          context.dispatch('getPricingYearlyStats', title)
        }
        context.commit('updateLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  savePricingStrategy: async function (context, { pricing, title, id }) {
    context.commit('updateLoading', true)
    const updateHash = {
      base_price: pricing.base_price,
      min_price_weekend: pricing.min_price_weekend,
      min_price_weekday: pricing.min_price_weekday,
      max_price: pricing.max_price,
      min_nights_weekend: pricing.min_nights_weekend,
      min_nights_weekday: pricing.min_nights_weekday,
      factors: pricing.factors,
      activated: pricing.activated,
      enable_emergency_mode: pricing.enable_emergency_mode,
      enable_group_pricing: pricing.enable_group_pricing,
      enable_multi_unit_pricing: pricing.enable_multi_unit_pricing,
      enable_trapped_between_weekends: pricing.enable_trapped_between_weekends,
      name: title,
    }

    try {
      if (id) {
        await axios.patch(`/api/pricing-strategies/${id}`, updateHash)
      } else {
        await axios.post(`/api/pricing-strategies/`, updateHash)
      }
      await context.dispatch('getPricingStrategies')
      context.commit('updateLoading', false)
    } catch (error) {
      alert(error)
    }
  },
  updateMarketingAction: function (context, marketingAction) {
    context.commit('updateLoading', true)
    axios
      .post(
        `/api/marketing/actions/edit/${marketingAction.id}`,
        marketingAction
      )
      .then(() => {
        context.commit('updateLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  editPricingSetting: function (context, pricingSetting) {
    context.commit('updateLoading', true)
    axios
      .post(`/api/pricing-setting`, { setting: pricingSetting })
      .then(function (response) {
        context.commit('updatePricingSetting', response.data.payload.data)
        context.commit('updateLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  undoApproval: async function (context, id) {
    context.commit('updateListingLoading', true)
    try {
      await axios.post(`/api/listing-tasks-new/${id}/unapprove`)
      await context.dispatch('getListingTaskBg', id)
      context.commit('updateListingLoading', false)
    } catch (error) {
      alert(error)
    }
  },
  rejectTask: async function (context, { id, reason, topic, status }) {
    context.commit('updateListingLoading', true)
    try {
      await axios.post(`/api/listing-tasks/${id}/reject`, {
        reason,
        status,
        topic,
      })
      await context.dispatch('getListingTaskBg', id)
      context.commit('updateListingLoading', false)
    } catch (error) {
      alert(error)
    }
  },
  addEventToSettings: function (context, newHoliday) {
    context.commit('updateLoading', true)
    axios
      .post(`/api/pricing-setting/add-holiday`, newHoliday)
      .then(function (response) {
        context.commit('updatePricingSetting', response.data.payload.data)
        context.commit('updateLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getPricingPreview: function (
    context,
    { pricing, title, strat_id, pricing_type, static_pricing }
  ) {
    const params = new URLSearchParams()
    params.append('base_price', pricing.base_price)
    params.append('max_price', pricing.max_price)
    params.append('min_price_weekend', pricing.min_price_weekend)
    params.append('pricing_type', pricing_type)
    params.append('static_pricing', static_pricing)
    params.append('min_price_weekday', pricing.min_price_weekday)
    params.append('factors', JSON.stringify(pricing.factors))
    return axios
      .get(`/api/pricing/${pricing.id}/preview`, { params })
      .then(response => {
        const payload = response.data.payload.data

        if (strat_id) {
          payload.pricing_strategy_id = strat_id
        }
        context.commit('updateCurrentPricing', payload)
        context.dispatch('getPricingMonthlyStats', {
          date: title,
        })
        context.dispatch('getPricingYearlyStats', title)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getExperiences: function (context, reservationID) {
    axios
      .get('/api/experiences', { params: { id: reservationID } })
      .then(function (response) {
        context.commit(
          'updateExperiences',
          response.data.payload.data.experiences
        )
      })
      .catch(function (error) {
        alert(error)
      })
  },
  updateListingPartial: function (
    context,
    { shouldUpdateState, updateCurrentListing, ...data }
  ) {
    axios
      .post(`/api/listings/${data.id}/update`, data)
      .then(res => {
        context.commit('updateLoading', false)
        if (shouldUpdateState) {
          context.commit('updateSingleListing', res.data.payload)
        }
        if (updateCurrentListing) {
          context.dispatch('changeListing', {
            listingId: data.id,
          })
        }
      })
      .catch(error => {
        context.commit('updateLoading', false)
        alert(error)
      })
  },
  getPricingEngineOptions: function (context) {
    axios.get('/api/listings/pricing-engines/available').then(response => {
      context.commit('updatePricingEngineOptions', response.data.payload.data)
    })
  },
  updatePricingEngine: function (context, { id, pricing_engine }) {
    axios
      .patch(`/api/listings/${id}/update-pricing-engine`, { pricing_engine })
      .then(response => {
        context.commit('updateListing', response.data.payload.data)
        context.dispatch('changeEditListing', id)
      })
  },
  getListingsPicker: function (context) {
    axios
      .get('/api/listings-picker')
      .then(response => {
        context.commit('updateListingsPicker', response.data)
      })
      .catch(() => {})
  },
  getPricingStrategies: function (context) {
    axios
      .get('/api/pricing-strategies')
      .then(response => {
        context.commit('updatePricingStrategiesList', response.data.strategies)
      })
      .catch(() => {})
  },
  setCurrentGuest: function (context, data) {
    context.commit('updateLoading', true)
    axios
      .post(`/api/marketing/guest/edit/${data.id}`, data)
      .then(function (response) {
        context.commit('updateLoading', false)
        context.commit('updateCurrentGuest', response.data.payload.data)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getCurrentGuest: function (context, id) {
    context.commit('updateLoading', true)
    axios
      .get(`/api/marketing/guest/${id}`)
      .then(function (response) {
        context.commit(
          'updateExperienceRequests',
          response.data.payload.data.guest_stats.experience_requests
        )
        context.commit('updateLoading', false)
        context.commit('updateCurrentGuest', response.data.payload.data)
        context.commit(
          'updateMarketingActions',
          response.data.payload.data.guest_stats.marketing_actions
        )
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getGuests: function (context, data) {
    context.commit('updateListingLoading', true)
    const params = {
      search: data.search || '',
      rowsPerPage: data.rowsPerPage || 20,
      page: data.page || 1,
    }

    axios
      .get(`/api/marketing/guest-list`, { params })
      .then(function (response) {
        context.commit('updateListingLoading', false)
        context.commit('updateGuests', response.data.payload.data)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getExperienceRequests: function (context) {
    context.commit('updateLoading', true)
    axios
      .get('/api/experience-requests')
      .then(function (response) {
        context.commit('updateLoading', false)
        context.commit(
          'updateExperienceRequests',
          response.data.payload.data.experience_requests
        )
      })
      .catch(function (error) {
        alert(error)
      })
  },
  updateIntegrations: function (context, integrations) {
    context.commit('updateListingLoading', true)
    axios
      .post(`/api/listings/${context.state.currentListing.id}/integrations`, {
        integrations: integrations,
      })
      .then(function (response) {
        context.commit(
          'updateListingIntegrations',
          response.data.payload.data.integrations
        )
        context.commit('updateListingLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  updateIntegration: function (_context, { listing }) {
    return axios
      .post(`/api/listings/${listing.id}/integrations`, {
        integrations: listing.integrations,
      })
      .catch(err => {
        console.log(err)
      })
  },
  clockInUser: async function (context, { id, user_id }) {
    context.commit('updateListingLoading', true)
    try {
      const { data } = await axios.post('/api/listing-task-clocking/in', {
        user_id: user_id,
        listing_task_id: id,
      })
      await context.dispatch('getListingTaskBg', data.listing_task_id)
      context.commit('updateListingLoading', false)
    } catch (error) {
      console.log(error)
    }
  },
  clockOutUser: async function (context, { id, user_id }) {
    context.commit('updateListingLoading', true)
    try {
      const { data } = await axios.post('/api/listing-task-clocking/out', {
        user_id: user_id,
        listing_task_id: id,
      })
      await context.dispatch('getListingTaskBg', data.listing_task_id)
      context.commit('updateListingLoading', false)
    } catch (error) {
      console.log(error)
    }
  },
  createCleaner: function (context, data) {
    context.commit('updateListingLoading', true)
    axios
      .post(`/api/users/cleaner`, {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone: data.phone,
      })
      .then(function (response) {
        context.commit('updateCleaners', response.data.payload.data.cleaners)
        context.commit('updateListingLoading', false)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  getPayments: async function (context, filters) {
    try {
      const { data } = await axios.get(`/api/payments`, { params: filters })
      context.commit('updateRecentPayments', data)
    } catch (error) {
      alert(error)
    }
  },
  removeUserClockIn: async function (context, timeId) {
    context.commit('updateListingLoading', true)
    try {
      const { data } = await axios.delete(
        `/api/listing-task-clocking/${timeId}`
      )
      await context.dispatch('getListingTaskBg', data.listing_task_id)
      context.commit('updateListingLoading', false)
    } catch (error) {
      alert(error)
    }
  },
  updateListingTaskClocking: async function (context, { timeId, duration }) {
    context.commit('updateListingLoading', true)
    try {
      const { data } = await axios.post(
        `/api/listing-task-clocking/${timeId}`,
        {
          duration,
        }
      )
      await context.dispatch('getListingTaskBg', data.listing_task_id)
      context.commit('updateListingLoading', false)
    } catch (error) {
      alert(error)
    }
  },
  markContractorPaymentAsProcessing(
    { state, commit, dispatch },
    { id, ...payload }
  ) {
    if (state.ltdSelectedIDS.size) {
      commit('updateListingLoading', true)
      return axios
        .post(`/api/contractor-payments/${id}/processing`, {
          ids: [...state.ltdSelectedIDS],
          ...payload,
        })
        .then(response => {
          commit('updateLtdSelectedIDS', new Set())
          dispatch('getContractorPaymentsDetails', { id, ...payload })
          return response.data
        })
        .catch(alert)
    }
  },
  downloadContractorPaymentFile(context, data) {
    if (context.state.ltdSelectedIDS.size) {
      return axios
        .post(
          `/api/contractor-payments/${data.id}/file`,
          { listing_tasks_ids: [...context.state.ltdSelectedIDS] },
          { responseType: 'blob' }
        )
        .then(response => {
          const date = moment.utc().format('YYMMDD-HHmm')
          const fileName = `NACHA-${data.name.substring(0, 15)}-${date}`
          common_functions.methods.autoDownloadBlobData(
            response.data,
            fileName + '.txt'
          )
          return fileName
        })
        .catch(async e => {
          try {
            const error = JSON.parse(
              await new Blob([e.response.data]).text()
            ).error
            alert(error)
          } catch (e) {
            //
          }
        })
    }
  },
  downloadListingsPaymentsFile(context, data) {
    return axios
      .post(
        `/api/payments/listings/${data.type}/file`,
        {
          ids: data.ids,
          date: data.date,
        },
        { responseType: 'blob' }
      )
      .then(response => {
        const date = moment.utc().format('YYMMDD-HHmm')
        const fileName = `NACHA-${data.type}-${date}`
        common_functions.methods.autoDownloadBlobData(
          response.data,
          fileName + '.txt'
        )
        return fileName
      })
      .catch(async e => {
        console.log(e)
        try {
          const error = JSON.parse(
            await new Blob([e.response.data]).text()
          ).error
          alert(error)
        } catch (e) {
          //
        }
      })
  },
  async updateAiPolicy({ commit }, data) {
    return await axios
      .patch(`/api/ai-policy`, data)
      .then(function (response) {
        commit('updateAiPolicy', response.data.payload.data)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  async getAiPolicy({ commit }) {
    const { data } = await axios.get(`/api/ai-policy`)
    commit('updateAiPolicy', data.payload.data)
  },
  setServiceCallModalOpened: function (context, data) {
    context.commit('updateServiceCallModalOpened', data)
  },
  setServiceCallAISuggestion: function (context, data) {
    context.commit('updateServiceCallAISuggestion', data)
  },
  getMonthSeasonFactor: function (context) {
    context.commit('updateLoading', true)
    axios
      .get('/api/pricing-setting/month-season-factor')
      .then(function (response) {
        context.commit('updateLoading', false)
        context.commit('updateMonthSeasonFactor', response.data.payload.data)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  updateMonthSeasonFactor: function (context, payload) {
    context.commit('updateLoading', true)
    axios
      .patch('/api/pricing-setting/month-season-factor', payload)
      .then(function (response) {
        context.commit('updateLoading', false)
        context.commit('updateMonthSeasonFactor', response.data.payload.data)
      })
      .catch(function (error) {
        alert(error)
      })
  },
  async getCouponsRules({ commit }, filters) {
    try {
      const response = await axios.get('/api/price-rules', {
        params: filters,
      })

      const { price_rules, pagi_info } = response.data.payload.data

      commit('updateCouponsRules', price_rules)
      commit('updateCouponsRulesPagination', pagi_info)
    } catch (error) {
      console.error('Error fetching price rules:', error)
    }
  },
  createCouponRule: function (context, payload) {
    axios.post(`/api/price-rules`, payload).then(function () {
      Toaster.show([
        { type: 'success', text: 'Coupon rule added successfully' },
      ])
      context.dispatch('getCouponsRules')
    })
  },
  editCouponRule: function (context, payload) {
    customAxios
      .patch(`/api/price-rules/${payload.id}`, payload)
      .then(function () {
        context.dispatch('getCouponsRules')
      })
      .catch(function (error) {
        console.error('Error editing price rules:', error)
      })
  },
  async getCoupons({ commit }, filters) {
    try {
      const response = await axios.get('/api/coupons', {
        params: filters,
      })

      const { coupons, pagi_info } = response.data.payload.data

      commit('updateCoupons', coupons)
      commit('updateCouponsPagination', pagi_info)
    } catch (error) {
      console.error('Error fetching coupons:', error)
    }
  },
  createCoupon: function (context, payload) {
    axios
      .post(`/api/coupons`, payload)
      .then(function () {
        Toaster.show([{ type: 'success', text: 'Coupon added successfully' }])
        context.dispatch('getCoupons')
      })
      .catch(function (error) {
        let errorMessage = 'An unknown error occurred'
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error
        }
        Toaster.show([
          {
            type: 'error',
            timeout: 8000,
            text: `Error creating coupon: ${errorMessage}`,
          },
        ])
      })
  },
  editCoupon: function (context, payload) {
    axios
      .patch(`/api/coupons/${payload.id}`, payload)
      .then(function () {
        context.dispatch('getCoupons')
      })
      .catch(function (error) {
        console.error('Error editing coupon:', error)
      })
  },
  deleteCoupon: function (context, id) {
    axios
      .delete(`/api/coupons/${id}`)
      .then(function () {
        Toaster.show([{ type: 'success', text: 'Coupon deleted successfully' }])
        context.dispatch('getCoupons')
      })
      .catch(function (error) {
        Toaster.show([
          {
            type: 'error',
            timeout: 8000,
            text: `Error deleting coupon: ${error}`,
          },
        ])
      })
  },
  createAiAgentVersion: function (context, payload) {
    axios.post(`/api/ai_agent_versions`, payload).then(function () {
      context.dispatch('getAiAgentVersions')
    })
  },
  async getlistingsNoScope({ commit }, filters = {}) {
    const response = axios.get(`/api/listings-all`, {
      params: filters,
    })
    response.then(data => {
      commit('updatelistingsNoScope', data.data)
    })
  },
  async getAiAgentVersions({ commit }, filters = {}) {
    try {
      const response = await axios.get('/api/ai_agent_versions', {
        params: filters,
      })

      const { ai_agent_versions, pagi_info } = response.data

      commit('updateAiAgentVersions', ai_agent_versions)
      commit('updateAiAgentVersionsPagination', pagi_info)
    } catch (error) {
      console.error('Error fetching AiAgentVersions:', error)
    }
  },
  createAiVersionControl: function (context, payload) {
    customAxios.post(`/api/ai-version-controls`, payload).then(function () {
      context.dispatch('getAiVersionControls')
    })
  },
  updateAiVersionControl: function (context, payload) {
    const {
      ai_version_control: { id, ...body },
    } = payload
    customAxios
      .patch(`/api/ai-version-controls/${id}`, body)
      .then(function () {
        context.dispatch('getAiVersionControls')
      })
      .catch(function (error) {
        console.error('Error updating AiVersionControl:', error)
      })
  },
  async getAiVersionControls({ commit }, filters = {}) {
    try {
      const response = await axios.get('/api/ai-version-controls', {
        params: filters,
      })

      const { ai_version_controls, pagi_info } = response.data

      commit('updateAiVersionControls', ai_version_controls)
      commit('updateAiVersionControlsPagination', pagi_info)
    } catch (error) {
      console.error('Error fetching AiVersionControls:', error)
    }
  },
  deployAiVersionControl: function (context, payload) {
    customAxios
      .post(`/api/ai_agent_versions/deploy`, payload)
      .then(function () {
        console.log('Sucess')
      })
      .catch(function (error) {
        console.error('Error deployAiVersionControl:', error)
      })
  },
  exportListingTask: async function ({ commit }, { id, format = 'pdf' }) {
    try {
      const response = await axios({
        url: `/api/listing-tasks/${id}/${format}`,
        method: 'GET',
        responseType: 'blob',
      })

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `listing_task_${id}.${format}`)
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error(`Error downloading ${format.toUpperCase()}:`, error)
    }
  },
}

// getters are functions
const getters = {
  inventoryPagination: function (state) {
    return state.inventoryPagination
  },
  isAdmin: function (state) {
    if (state.user) {
      return state.user.role === 'admin'
    } else {
      return false
    }
  },
  isExperienceManager: function (state) {
    if (state.user) {
      return state.user.role === 'experience-manager'
    } else {
      return false
    }
  },
  currentListingId: function (state) {
    if (['view-property', 'manage-property'].includes(state.route.name)) {
      return toNumber(state.route.params.id)
    }
    return null
  },
  isCommunicationManager: function (state) {
    if (state.user) {
      return state.user.role === 'communication-manager'
    } else {
      return false
    }
  },
  isSalesManager: function (state) {
    if (state.user) {
      return state.user.role === 'sales-manager'
    } else {
      return false
    }
  },
  CurrentUserId: function (state) {
    return state.user.id
  },
  isCommunicationAgent: function (state) {
    if (state.user) {
      return (
        state.user.role === 'communication-agent' ||
        state.user.role === 'sales-agent'
      )
    } else {
      return false
    }
  },
  isSalesAgent: function (state) {
    if (state.user) {
      return state.user.role === 'sales-agent'
    } else {
      return false
    }
  },
  currentUserId: function (state) {
    if (state.user) {
      return state.user.id
    }
    return null
  },
  currentUserName: function (state) {
    if (state.user) {
      return state.user.name
    }
    return null
  },
  currentUser: function (state) {
    if (state.user) {
      return state.user
    }
    return null
  },
  activeCommUsers: function (state) {
    return state.activeCommUsers
  },
  isCleaner: function (state) {
    if (state.user) {
      return state.user.role === 'cleaner'
    } else {
      return false
    }
  },
  isPropertyManager: function (state) {
    if (state.user) {
      return state.user.role === 'property-manager'
    } else {
      return false
    }
  },
  isOperationsPersonal: function (state) {
    if (state.user) {
      return (
        state.user.role === 'property-manager' ||
        state.user.role === 'field-pm' ||
        state.user.role === 'coordinator' ||
        state.user.abilities.indexOf('personnel-operations') > -1
      )
    } else {
      return false
    }
  },
  isFieldPm: function (state) {
    if (state.user) {
      return state.user.role === 'field-pm'
    } else {
      return false
    }
  },
  isCoordinator: function (state) {
    if (state.user) {
      return state.user.role === 'coordinator'
    } else {
      return false
    }
  },
  isCleaningManager: function (state) {
    if (state.user) {
      return (
        state.user.role === 'cleaning-manager' ||
        state.user.role === 'cleaning-supervisor'
      )
    } else {
      return false
    }
  },
  isCleaningSupervisor: function (state) {
    if (state.user) {
      return state.user.role === 'cleaning-supervisor'
    } else {
      return false
    }
  },
  isReferrer: function (state) {
    if (state.user) {
      return state.user.id === state.currentListing.referrer_id
    } else {
      return false
    }
  },
  isGarbageUser: function (state) {
    if (state.user && state.user.abilities) {
      let index = state.user.abilities.indexOf('garbage patrol')
      if (index > -1) {
        return true
      }
    } else {
      return false
    }
  },
  isSecurityUser: function (state) {
    if (state.user && state.user.abilities) {
      let index = state.user.abilities.indexOf('security patrol')
      if (index > -1) {
        return true
      }
    } else {
      return false
    }
  },
  isInvestor: function (state) {
    if (state.user) {
      return state.user.role === 'investor'
    } else {
      return false
    }
  },
  isTourist(state) {
    return state.user.role === 'tourist'
  },
  isContractor: function (state) {
    if (state.user) {
      return state.user.role === 'contractor'
    } else {
      return false
    }
  },
  isAccountant: function (state) {
    if (state.user) {
      return state.user.role === 'accountant'
    } else {
      return false
    }
  },
  isHotelReception: function (state) {
    if (state.user) {
      return state.user.role === 'hotel-reception'
    } else {
      return false
    }
  },
  isAgent: function (state) {
    if (state.user) {
      return state.user.role === 'agent'
    } else {
      return false
    }
  },
  isInvestmentManager: function (state) {
    if (state.user) {
      return state.user.role === 'investments-manager'
    } else {
      return false
    }
  },
  chatMobileView: state => {
    return state.chatMobileView
  },
  currentConversation(state) {
    return state.chosenChatConversation
  },
  conversationLoading(state, getters, rootState) {
    const conversation = find(state.chatConversations, c => {
      return c.guesty_id === rootState.route.params.id
    })
    return (
      state.conversationLoading &&
      conversation &&
      !conversation.thread_plus_dvr_log
    )
  },
  username: state => {
    if (!state.user) {
      return ''
    }
    const { first_name, last_name, email } = state.user
    if (first_name && last_name) {
      return `${first_name} ${last_name}`
    }
    return email.split('@')[0]
  },
  chosenChatReservation(state) {
    return state.chosenChatReservation || {}
  },
  currentListingTask(state) {
    return state.currentlistingTask
  },
  assignedContractor(state, getters, rootState, rootGetters) {
    return (
      rootGetters['users/usersMap'][
        getters.currentListingTask.assigned_contractor_id
      ] || {}
    )
  },
  projectNames(state) {
    return projectNames(state.listingTasks)
  },
  listingsPicker(state, getter) {
    return state.listingsIndex.filter(l => l.active && !l.second_guesty_id)
  },
  listingsActiveAndDups() {
    return state.listingsIndex.filter(l => l.active)
  },
}

export const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    app: AppModule,
    bankAccounts: BankAccountsModule,
    businessModel: businessModelModule,
    checkout: CheckoutModule,
    expenses: ExpenseModule,
    guests: guestModule,
    inventory: InventoryModule,
    iot: iotModule,
    kpis: kpisModule,
    listingInfoDef: listingInfoDefinitionsModule,
    listingSetup: listingSetupModule,
    listingTask: listingTaskModule,
    listingCalendar: listingCalendarModule,
    listings: ListingsModule,
    modal: modalModule,
    notifications: NotificationsModule,
    projects: ProjectsModule,
    ratePlan: RatePlanModule,
    reservation: ReservationModule,
    reviews: ReviewsModule,
    shoppingList: ShoppingListModule,
    storages: StoragesModule,
    taskim: TaskimModule,
    estimates: EstimatesModule,
    store: StoreAdminModule,
    stripe: StripeModule,
    taskCalendar: calendarModule,
    tasksCatalog: ListingTaskCatalogItemModule,
    userGroups: userGroupsModule,
    users: userModule,
    warranty: WarrantyModule,
    housekeeping: HousekeepingModule,
    regions: RegionsModule,
    zones: ZonesModule,
    listingReport: ListingReportModule,
    taxProfile: TaxProfileModule,
    pricingStrategy: PricingStrategyModule,
    settings: SettingsModule,
    billing: BillingModule,
    conversations: ConversationsModule,
    tenants: TenantsModule,
    smartRule: SmartRuleModule,
    losSetting: LosSettingModule,
    expenseTypes: ExpenseTypesModule,
    feeds: FeedModule,
    seamIot: SeamIotModule,
    templates: TemplatesModule,
    dashboard: DashboardModule,
  },
})
