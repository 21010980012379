import VueRouter from 'vue-router'
import Vue from 'vue'
import last from 'lodash/fp/last'
Vue.use(VueRouter)

// Import critical components that should be loaded immediately
import RootLayout from 'components/layouts/root-layout'
import Login from 'components/login'
import ResetPasswordPage from 'components/reset-password-page'
import Error from 'components/error'

// Remove all other component imports since we're using dynamic imports

const routes = [
  {
    path: '/storybook',
    component: () =>
      import(/* webpackChunkName: "storybook" */ 'components/storybook.vue'),
  },
  { path: '/', redirect: '/dashboard' },
  {
    path: '/dashboard',
    component: RootLayout,
    children: [
      {
        name: 'SystemDashboard',
        path: 'system',
        component: () => import('components/system-dashboard'),
      },
      {
        name: 'Taskim',
        path: 'taskim/:boardId?',
        component: () =>
          import(/* webpackChunkName: "taskim" */ 'components/taskim/taskim'),
      },
      {
        name: 'Feed',
        path: 'feeds/:feedId?',
        component: () =>
          import(/* webpackChunkName: "feed" */ 'components/feed.vue'),
      },
      {
        name: 'GuestBook',
        path: 'guest-book',
        component: () =>
          import(
            /* webpackChunkName: "guest-book" */ 'components/guests/guest-book.vue'
          ),
      },
      {
        name: 'ChannelManager',
        path: 'cm/:paramsTab?',
        component: () =>
          import(
            /* webpackChunkName: "channel-management" */ 'components/channel-management/cm-dash.vue'
          ),
      },
      {
        name: 'Project',
        path: 'projects/:projectId/:paramsTab?',
        component: () =>
          import(
            /* webpackChunkName: "projects" */ 'components/projects/project'
          ),
      },
      {
        name: 'Expense',
        path: 'expenses/:expenseId',
        component: () =>
          import(
            /* webpackChunkName: "expenses" */ 'components/expenses/expense'
          ),
      },
      {
        name: 'Automate',
        path: 'automate/:paramsTab?',
        component: () =>
          import(
            /* webpackChunkName: "automations" */ 'components/automations-dash'
          ),
      },
      {
        name: 'Estimates',
        path: 'estimates/:paramsTab?/:estimateId?',
        component: () =>
          import(
            /* webpackChunkName: "estimates" */ 'components/estimates/estimates-page'
          ),
      },
      {
        name: 'Listings',
        path: 'listings',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ 'components/dashboard-page'
          ),
      },
      {
        path: 'sales',
        component: () =>
          import(
            /* webpackChunkName: "sales-panel" */ 'components/sales-panel'
          ),
      },
      {
        path: 'listings-search',
        component: () =>
          import(
            /* webpackChunkName: "listings-search" */ 'components/listings-search'
          ),
      },
      {
        path: 'sales-chat/:id?',
        component: () =>
          import(/* webpackChunkName: "base-chat" */ 'components/base-chat'),
        name: 'guests-chat',
      },
      {
        path: 'investment-finder',
        component: () =>
          import(
            /* webpackChunkName: "investment-finder" */ 'components/investment-finder'
          ),
      },
      {
        path: 'listings-setup/:paramsTab?',
        component: () =>
          import(
            /* webpackChunkName: "listing-setups" */ 'components/listing-setups'
          ),
      },
      {
        name: 'manage-property',
        path: 'edit/:id/:paramsTab?',
        component: () =>
          import(
            /* webpackChunkName: "listing-management" */ 'components/listing_management'
          ),
      },
      {
        path: 'experience-management',
        component: () =>
          import(
            /* webpackChunkName: "experience-manager" */ 'components/experience-manager'
          ),
      },
      {
        path: 'pricing/:id',
        component: () =>
          import(
            /* webpackChunkName: "pricing-page" */ 'components/pricing-page'
          ),
      },
      {
        name: 'view-property',
        path: 'property/:id/:paramsTab?',
        component: () =>
          import(
            /* webpackChunkName: "listing-page" */ 'components/listing/listing-page'
          ),
      },
      {
        path: 'company-activity',
        component: () =>
          import(/* webpackChunkName: "activity" */ 'components/activity'),
      },
      {
        path: 'catalog',
        component: () =>
          import(
            /* webpackChunkName: "listing-task-catalog" */
            'components/listing-tasks-catalog/listing-task-catalog-items-table'
          ),
      },
      {
        path: 'marketing/:paramsTab?',
        component: () =>
          import(/* webpackChunkName: "marketing" */ 'components/marketing'),
      },
      {
        path: 'guests/:id',
        component: () =>
          import(/* webpackChunkName: "guest" */ 'components/guest'),
      },
      {
        path: 'ai-config/:paramsTab?',
        component: () =>
          import(
            /* webpackChunkName: "ai-manager" */ 'components/ai/ai-manager.vue'
          ),
      },
      {
        path: 'management/:paramsTab?',
        component: () =>
          import(
            /* webpackChunkName: "management-page" */ 'components/management-page'
          ),
        name: 'setting-page',
        children: [
          {
            name: 'integration-layout',
            path: '/',
            component: () =>
              import(
                /* webpackChunkName: "integrations-layout" */ 'components/integrations-layout.vue'
              ),
            children: [
              {
                path: '/',
                component: () =>
                  import(
                    /* webpackChunkName: "integrations" */ 'components/integrations.vue'
                  ),
              },
              {
                path: 'integration/:name',
                name: 'integration-page',
                component: () =>
                  import(
                    /* webpackChunkName: "integration-page" */ 'components/marketplace/integration-page.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'inventory/:paramsTab?',
        component: () =>
          import(
            /* webpackChunkName: "inventory-page" */ 'components/inventory/inventory-page'
          ),
      },
      {
        path: '/dashboard/listing-setup/:id/:paramsTab?',
        component: () =>
          import(
            /* webpackChunkName: "listing-setup-page" */ 'components/listing-setup-page'
          ),
      },
      {
        name: 'payables',
        path: 'payables/:paramsTab?',
        component: () =>
          import(/* webpackChunkName: "payables" */ 'components/payables'),
      },
      {
        name: 'bank-feeds',
        path: 'bank-feeds/:paramsTab?',
        component: () =>
          import(/* webpackChunkName: "bank-feeds" */ 'components/bank-feeds'),
      },
      {
        name: 'ledger-wallet',
        path: 'ledger-wallet/:paramsTab?',
        component: () =>
          import(
            /* webpackChunkName: "ledger-wallet" */ 'components/accounting/ledger-wallet.vue'
          ),
      },
      {
        name: 'financials',
        path: 'financials/:paramsTab?',
        component: () =>
          import(/* webpackChunkName: "financials" */ 'components/financials'),
      },
      {
        path: 'pricing-panel/:paramsTab?',
        component: () =>
          import(/* webpackChunkName: "pricings" */ 'components/pricings'),
      },
      {
        name: 'service-calls',
        path: 'service-calls/:paramsTab?',
        component: () =>
          import(
            /* webpackChunkName: "listing-tasks-dash" */ 'components/listing-tasks-dash'
          ),
        children: [
          {
            name: 'area-report',
            path: '/',
            component: () =>
              import(
                /* webpackChunkName: "listing-report-layout" */ 'components/listing-report/listing-report-layout'
              ),
            children: [
              {
                path: '/',
                name: 'region-report',
                component: () =>
                  import(
                    /* webpackChunkName: "region-report-page" */ 'components/listing-report/region-report-page'
                  ),
              },
              {
                path: 'listing/:id',
                name: 'listing-report',
                component: () =>
                  import(
                    /* webpackChunkName: "listing-report-single" */ 'components/listing-report/listing-report-single'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'performance/:paramsTab?',
        component: () =>
          import(
            /* webpackChunkName: "performance" */ 'components/performance'
          ),
      },
      {
        path: 'dev-tools/:paramsTab?',
        component: () =>
          import(/* webpackChunkName: "dev-tools" */ 'components/dev-tools'),
      },
      {
        path: 'listing-task/:id',
        component: () =>
          import(
            /* webpackChunkName: "listing-task-layout" */
            'components/listing-tasks/single-listing-task/listing-task-layout'
          ),
        name: 'lt-page',
      },
      {
        path: 'reservations-manage',
        component: () =>
          import(
            /* webpackChunkName: "reservation-manager" */ 'components/reservation-manager'
          ),
      },
      {
        path: 'reservation/:id',
        component: () =>
          import(
            /* webpackChunkName: "reservation-page" */ 'components/reservation/reservation-page'
          ),
      },
      {
        path: 'field-management',
        component: () =>
          import(
            /* webpackChunkName: "requests-table" */ 'components/inventory/requests/requests-table'
          ),
      },
      { path: 'error', component: Error },
      {
        path: 'user-profile/:paramsTab?',
        component: () =>
          import(
            /* webpackChunkName: "user-layout" */ 'components/user/user-layout'
          ),
      },
      {
        path: 'operations-settings',
        name: 'operations-settings',
        component: () =>
          import(
            /* webpackChunkName: "operations-settings" */ 'components/operations/operations-settings'
          ),
      },
      {
        path: 'iot',
        component: () =>
          import(
            /* webpackChunkName: "iot-dashboard" */ 'components/iot/iot-dashboard'
          ),
      },
      {
        path: 'store/:paramsTab?',
        component: () =>
          import(
            /* webpackChunkName: "store-view" */ 'components/store/store-view'
          ),
      },
      {
        path: 'hotel-dashboard',
        component: () =>
          import(
            /* webpackChunkName: "hotel-dashboard-page" */ 'components/hotel-dashboard/hotel-dashboard-page'
          ),
      },
      {
        path: 'multi-calendar',
        name: 'multi-calendar',
        component: () =>
          import(
            /* webpackChunkName: "multi-cal" */ 'components/calendar/multi-cal.vue'
          ),
      },
      {
        path: 'multi-calendar_v1',
        name: 'multi-calendar_v1',
        component: () =>
          import(
            /* webpackChunkName: "multi-calendar" */ 'components/calendar/multi-calendar'
          ),
      },
      {
        path: 'multi-calendar_v2',
        name: 'multi-calendar_v2',
        component: () =>
          import(
            /* webpackChunkName: "multi-cal-v2" */ 'components/calendar/multi-cal.vue'
          ),
      },
      {
        path: 'portfolio/:paramsTab?',
        name: 'portfolio',
        component: () =>
          import(
            /* webpackChunkName: "portfolio-page" */ 'components/portfolio/portfolio-page.vue'
          ),
      },
      {
        path: 'guest-experience/:paramsTab?/:conversationId?',
        name: 'sales',
        component: () =>
          import(
            /* webpackChunkName: "guest-experience" */ 'components/crm/guest-experience'
          ),
      },
      {
        path: 'guest-experience/:paramsTab?',
        name: 'crm',
        component: () =>
          import(
            /* webpackChunkName: "guest-experience-crm" */ 'components/crm/guest-experience'
          ),
      },
      {
        path: 'reviews-dashboard/:paramsTab?',
        name: 'review-crm',
        component: () =>
          import(
            /* webpackChunkName: "review-crm" */ 'components/crm/review-crm.vue'
          ),
      },
      {
        path: 'guest-mgmt/:paramsTab?',
        name: 'guest-exp',
        component: () =>
          import(
            /* webpackChunkName: "guests-mgmt" */ 'components/guests/guests-mgmt'
          ),
      },
      {
        path: 'auto-tasks',
        name: 'auto-tasks',
        component: () =>
          import(/* webpackChunkName: "auto-tasks" */ 'components/auto-tasks'),
      },
      {
        path: 'auto-messages',
        name: 'auto-messages',
        component: () =>
          import(
            /* webpackChunkName: "auto-messages" */ 'components/auto-messages'
          ),
      },
      {
        path: 'agent-reservations',
        name: 'agent-reservations',
        component: () =>
          import(
            /* webpackChunkName: "agent-reservations" */ 'components/agents/agent-reservations.vue'
          ),
      },
      { path: '/', redirect: '/dashboard/listings' },
      {
        path: 'management/ledger-accounts/:id',
        name: 'ledger-transactions',
        component: () =>
          import(
            /* webpackChunkName: "ledger-transactions" */ './components/accounting/ledger-transactions.vue'
          ),
      },
      {
        path: 'multi-cal',
        name: 'dashboard-multi-cal',
        component: () =>
          import(
            /* webpackChunkName: "dashboard-multi-cal" */ 'components/calendar/multi-cal.vue'
          ),
      },
      {
        name: 'invoices',
        path: 'invoices',
        component: () =>
          import(
            /* webpackChunkName: "invoice-page" */ 'components/invoices/invoice-page'
          ),
      },
    ],
  },
  { path: '/login', component: Login },
  { path: '/reset-password', component: ResetPasswordPage },
  {
    path: '/tourist-lt',
    component: () =>
      import(
        /* webpackChunkName: "listing-task-tracker-layout" */ 'components/layouts/listing-task-tracker-layout'
      ),
    children: [
      {
        path: '/tourist-lt/:id',
        component: () =>
          import(
            /* webpackChunkName: "listing-task-tracker" */ 'components/listing-tasks/listing-task-tracker'
          ),
      },
    ],
  },
  {
    path: '/task-report/:id',
    component: () =>
      import(
        /* webpackChunkName: "listing-task-report" */ 'components/listing-tasks/listing-task-report'
      ),
  },
  {
    path: '/management/tenant',
    component: () =>
      import(
        /* webpackChunkName: "create-tenant-page" */ 'components/create-tenant-page'
      ),
  },
]
let pendingNavigation = null

export const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior({ matched: matched1 }, { matched: matched2 }) {
    if (last(matched1).path !== last(matched2).path) {
      return { x: 0, y: 0 }
    }
  },
})
router.beforeEach((to, from, next) => {
  console.log(to)
  pendingNavigation = to
  next()
})
router.afterEach((to, from, next) => {
  console.log(to)
  pendingNavigation = null
})

router.onError(error => {
  console.log(error)
  if (error.message && error.message.includes('Loading chunk')) {
    console.log('Router detected chunk loading error, reloading page...')

    // Get the destination route that failed
    const destination = pendingNavigation

    if (destination) {
      window.location.href = destination.fullPath
      console.log(`Stored intended route: ${destination.fullPath}`)
    }
    // Reload the page
  }
})
